import classnames from 'classnames';
import {determineTarget, determineRel} from '../helpers/homePageHelpers';
import {connect} from 'react-redux';

import * as AnalyticsActions from '../../actions/analyticsActions';
import {getScreenType} from '../../constants/mParticle';

function textIntoParagraphs(text, id) {
  return text
    .split('\n\n')
    .map((graf, index) => <p key={`commentary-${id}-graf-${index}`}>{graf}</p>);
}

function Commentary(props) {
  if (!props.title && !props.description) {
    return false;
  }
  const classes = classnames({
    atom: true,
    commentary: true,
  });

  const handleClick = () => {
    props.trackEvent({
      tag_manager_event: 'content_selected',
      urlHash: props.titleLinkUrl,
      screen: getScreenType(props.ui?.pageType, props.ui?.pageSubType),
      contentType: props.contentType || 'stream',
      springType: 'hyperlink',
      title: props.title,
    });
  };

  const getTitle = () => {
    if (props.titleLinkUrl) {
      const target = determineTarget(props.titleLinkUrl);
      const rel = determineRel(target);

      return (
        <h3>
          <a href={props.titleLinkUrl} target={target} rel={rel} onClick={handleClick}>
            {props.title}
          </a>
        </h3>
      );
    }
    return <h3>{props.title}</h3>;
  };

  return (
    <div className={classes}>
      {props.title ? getTitle() : false}
      {props.children}
      {props.description ? textIntoParagraphs(props.description, props.id) : false}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    ui: state.ui,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEvent: (event) => dispatch(AnalyticsActions.trackEvent(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Commentary);
