import React from 'react';
import classnames from 'classnames';
import {buildQueryString} from 'url-params-helper';
import {SVG} from '@br/br-components';
import * as playerui from 'bitmovin-player-ui';
import loadable from '@loadable/component';

import {Static} from '../../../endpoints';
import YoutubeEmbed from '../../molecules/video/youtubeEmbed';
import VideoIndicator from '../../atoms/videoIndicator';
import logger from '../../../logger';
import Badge from '../../atoms/badge';
import {videoStates} from '../../../constants/videoStates';
import {sanitizeTitle} from '../../../helpers/stringHelpers';
import {hasClass, addClass} from '../../../apis/helpers/dom_helpers';
import {generateConvivaConfig} from './analytics/conviva';
import * as StorageAPI from '../../../apis/storage_api';
import {getArticleThumb} from '../../../helpers/imageHelpers';

// Consider creating a dev only key and using an env var in the future
// For now we are using the same license for all env
const PLAYER_KEY = '0b7f53b7-50bd-4fcf-9b07-4697d847e697';
// Conviva requires a valid key even in dev env
const CONVIVA_KEY = process.env.CONVIVA_CUSTOMER_KEY || '0c03b8bbf14bc87e6497b4f7d75ff5b4d35853b1';
const CONVIVA_URL = process.env.CONVIVA_SERVICE_URL || '';
const squareSize = '500x500';
const standardSize = '640x480';
const verticalSize = '360x640';
const closedCaptionId = 'en';
const defaultHideDelay = 3000;
const squareRatio = 1; // 1:1
const standardRatio = 1.33; // 4:3
const verticalRatio = 0.56; // 9:16
const STATE_PLAYING = 'playing';

const THREE_SECOND_MARK_DURATION = 3;

global.VidBitmovin = null;
// IAS video integration
const BR_CAMP_ID = '640x480';
const PARTNER_CODE = 'turner';
const BR_ADVERTISER_ID = '925660';
// const function for setting up IAS<=>IMA SDK Adapter
const initializeIASImaSDKAdapter = (adsManager) => {
  const config = {
    anId: BR_ADVERTISER_ID,
    campId: BR_CAMP_ID,
    ias_xar: '1', // 1 represents true in IAS parlace
    ias_xpb: '1',
    ias_xps: 'autoplayed',
    partner: PARTNER_CODE,
  };
  const videoElement = document.querySelector("[id^='video-wrapper'");
  global.googleImaVansAdapter.init(global.google, adsManager, videoElement, config);
};

class VideoPlayer extends React.Component {
  static defaultProps = {
    article: {},
    author: {},
    autoplay: false,
    badge: {},
    handleContentBox: () => {}, // stub
    hlsMediaUrl: '',
    isPlaylist: false,
    isYoutube: false,
    metadata: {
      provider_name: '',
      video_id: null,
      video_url: '',
      impression_tracking: [],
    },
    page: {},
    trackEvent: () => {},
    updateVideoPlayerReference: () => {},
    user: {},
    videoRecommendations: [],
  };

  constructor(props) {
    super(props);
    this.onAdBreakFinished = this.onAdBreakFinished.bind(this);
    this.onAdBreakStarted = this.onAdBreakStarted.bind(this);
    this.onAdEnd = this.onAdEnd.bind(this);
    this.onAdStart = this.onAdStart.bind(this);
    this.onAdSkipped = this.onAdSkipped.bind(this);
    this.onAdError = this.onAdError.bind(this);
    this.onDisplayChange = this.onDisplayChange.bind(this);
    this.onEnd = this.onEnd.bind(this);
    this.onError = this.onError.bind(this);
    this.onPause = this.onPause.bind(this);
    this.onPlayStateChange = this.onPlayStateChange.bind(this);
    this.onStart = this.onStart.bind(this);
    this.onUnload = this.onUnload.bind(this);
    this.getAdParams = this.getAdParams.bind(this);
    this.getConvivaAdInfo = this.getConvivaAdInfo.bind(this);
    this.scheduleAds = this.scheduleAds.bind(this);
    this.setupPlayer = this.setupPlayer.bind(this);
    this.onMediaLoaded = this.onMediaLoaded.bind(this);
    this.playNextRecommendedVideo = this.playNextRecommendedVideo.bind(this);
    this.updateUIManager = this.updateUIManager.bind(this);
    this.onMuted = this.onMuted.bind(this);
    this.onUnMuted = this.onUnMuted.bind(this);
    this.onSubtitleEnable = this.onSubtitleEnable.bind(this);
    this.onTimeChanged = this.onTimeChanged.bind(this);
    this.onVideoIndicatorClick = this.onVideoIndicatorClick.bind(this);

    this.setVideoRef = (element) => {
      this._video = element;
    };

    this.increment = 93;
    this.thumbWidth = 32;
    this.gutters = 2;

    this.state = {
      contentPlacement: 0,
      countdownTimer: 1, // A timer that counts down till the next video plays automatically
      currentAdBreak: null,
      errorState: false,
      impressionTrackersFired: false,
      isCCEnabled: false,
      isMuted: true,
      metadata: {},
      player: null,
      position: 0,
      remainingRecommendedVideos: [],
      remainingTime: 0, // time remaining in seconds for the next video to be played.
      showVideoPlayer: true,
      viewedVideos: [],
      watchedVideos: [],
    };
  }

  componentDidMount() {
    this.setRecommendationOverlayData();
    global.addEventListener('beforeunload', this.onUnload, false);
    if (!this._addedPlayer && !this.props.isYoutube) {
      if (!global.VidBitmovin) {
        if (!this.props.loadScript) {
          return;
        }

        const convivaSdkOnLoad = async () => {
          const bitmovinPlayerModule = loadable.lib(() => import('bitmovin-player'), {
            ssr: false,
          });
          const bitDefault = await bitmovinPlayerModule.load();

          global.VidBitmovin = bitDefault;

          const convivaModule = loadable.lib(() => import('@bitmovin/player-integration-conviva'), {
            ssr: false,
          });

          const {ConvivaAnalytics} = await convivaModule.load();

          global.VidConvivaAnalytics = ConvivaAnalytics;

          this.setupPlayer();
        };

        Promise.all([
          this.props.loadScript({
            key: 'conviva_sdk',
            src: `${Static.assets()}/vendor/bitmovin/conviva-core-sdk.min.js`,
            async: false,
            onLoad: convivaSdkOnLoad,
          }),
        ]);
      } else {
        this.setupPlayer();
      }
    }
  }

  shouldComponentUpdate() {
    return true;
  }

  // handles video updates
  componentDidUpdate(prevProps) {
    const videoId = this.props.metadata && this.props.metadata.id;
    const videoCarouselHeaders = [...document.querySelectorAll('.bmpui-carousel-header')];

    if (videoId !== prevProps.metadata.id) {
      this.removeRecommendedOverlays();
      this.loadMedia();
      this.updateUIManager();
    }

    if (!this.state.countdownTimer && videoCarouselHeaders.length) {
      videoCarouselHeaders.forEach((header) => {
        header.innerHTML = 'Related Videos';
      });
    }
  }

  componentWillUnmount() {
    this.onUnload();
    this.clearCountdownTimer();
    global.removeEventListener('beforeunload', this.onUnload, false);
  }

  addEventListeners(player) {
    const {PlayerEvent} = global.VidBitmovin;
    player.on(PlayerEvent.AdBreakStarted, this.onAdBreakStarted);
    player.on(PlayerEvent.AdBreakFinished, this.onAdBreakFinished);
    player.on(PlayerEvent.AdManifestLoaded, this.onAdManifestStarted);
    player.on(PlayerEvent.AdStarted, this.onAdStart);
    player.on(PlayerEvent.AdSkipped, this.onAdSkipped);
    player.on(PlayerEvent.AdFinished, this.onAdEnd);
    player.on(PlayerEvent.AdError, this.onAdError);
    player.on(PlayerEvent.ViewModeChanged, this.onDisplayChange);
    player.on(PlayerEvent.Error, this.onError);
    player.on(PlayerEvent.PlaybackFinished, this.onEnd);
    player.on(PlayerEvent.Paused, this.onPause);
    player.on(PlayerEvent.Playing, this.onStart);
    player.on(PlayerEvent.Muted, this.onMuted);
    player.on(PlayerEvent.Unmuted, this.onUnMuted);
    player.on(PlayerEvent.SubtitleEnable, this.onSubtitleEnable);
    player.on(PlayerEvent.TimeChanged, this.onTimeChanged);
  }

  setRecommendationOverlayData() {
    const {metadata} = this.props;
    const hasRecommendations =
      this.props.videoRecommendations && this.props.videoRecommendations.length;

    if (hasRecommendations) {
      let allRecomendations = [];
      if (
        this.props.videoMetadata &&
        (this.props.videoMetadata.length || this.props.videoRecommendations.length)
      ) {
        allRecomendations = this.props.videoRecommendations.reduce((acc, current) => {
          const id = current.video_id ? current.video_id : current.metadata.video_id;
          const video = this.props.videoMetadata[id];
          if (video && video.thumbnail_url) {
            const formattedVideo = {...video, ...{thumbnail: video.thumbnail_url}};
            formattedVideo.id && acc.push(formattedVideo);
          }
          return acc;
        }, []);
      }
      if (!allRecomendations.length) {
        allRecomendations = this.props.videoRecommendations;
      }
      if (allRecomendations && allRecomendations.length > 1) {
        const remainingRecommendedVideos = allRecomendations.filter((video) => {
          return video.id !== metadata.id;
        });
        // number of videos * %width of each thumbnail - 100%
        this.videoPlaylistWidth = remainingRecommendedVideos.length * this.thumbWidth - 100;
        this.setState({remainingRecommendedVideos, displayRightArrow: this.videoPlaylistWidth > 0});
      }
    }
  }

  completionInterval() {
    const duration = this.state.player.getDuration();
    const currentTime = this.state.player.getCurrentTime();

    // The % interval of completion of the video - 0, 25, 50, 75, 95, 100 - note this should round down to the nearest interval value (ie. a user that complete 33% of the video will be tracked at 25)
    const completionInterval = Math.round(((currentTime / duration) * 100) / 25) * 25;
    return isNaN(completionInterval) ? 0 : completionInterval;
  }

  convertToSlug(str = '') {
    return str.toLowerCase().replace(/_/g, '-');
  }

  placementType() {
    if (this.props.page.zone === 'video') {
      return 'article_L1';
    }
    if (this.props.page.type === 'article') {
      return 'article_body';
    }
    return 'section';
  }

  sharedMediaAttributes() {
    const {metadata} = this.props;
    const {analytics = {}} = metadata;
    const createdAt = metadata.created_at;
    const published_at =
      createdAt && createdAt.toISOString && createdAt.toISOString()
        ? createdAt.toISOString()
        : createdAt;
    const videoRecommendations = this.props.videoRecommendations;
    const hasRecommendations = videoRecommendations && videoRecommendations.length;

    // Note: with the migration to TOP 1.5 we will look to have a common interface so multiple checks
    // will not be needed for the same value type.
    const videoId =
      metadata.video_id ||
      metadata.id ||
      this.props.video_id ||
      this.props.id ||
      analytics.video_id;

    return {
      ...this.contentScore,
      ...this.props.progressType,
      autoplay: this.props.autoplay,
      content_id: Number(videoId),
      content_placement: hasRecommendations ? this.state.contentPlacement : 0,
      content_type: this.props.contentType === videoStates.REPLAY ? 'VOD' : this.props.contentType,
      experiment_type: hasRecommendations ? this.props.experimentType : '',
      hook: this.props.title || analytics.title,
      placement_type: this.placementType(),
      progress_type: this.state.progressType || '',
      published_at: published_at || analytics.published_at,
      source: metadata.provider_name,
      video_player_experience: hasRecommendations ? 'video_next_playlist' : '',
      video_title: metadata.title || analytics.title,
    };
  }

  pageSpecificAttributes(pageType) {
    if (pageType === 'stub') {
      return {
        article_type: 'stub',
      };
    }
    return {};
  }

  determineClosestAspectRatio({height, width}) {
    const aspectRatios = [squareRatio, standardRatio, verticalRatio];
    const currentRatio = width / height;
    const closestAspectRatio = aspectRatios.reduce((acc, aspectRatio) => {
      return Math.abs(currentRatio - acc) >= Math.abs(currentRatio - aspectRatio)
        ? aspectRatio
        : acc;
    }, standardRatio);

    return closestAspectRatio;
  }

  determineAdTagSize({height, width}) {
    const closestAspectRatio = this.determineClosestAspectRatio({height, width});

    switch (closestAspectRatio) {
      case squareRatio: {
        // Aspect Ratio 1:1
        return squareSize;
      }
      case verticalRatio: {
        // Aspect Ratio 9:16
        return verticalSize;
      }
      case standardRatio:
      default: {
        // Aspect Ratio 4:3
        return standardSize;
      }
    }
  }

  getAdParams() {
    const {id, mediaWidth, mediaHeight, title, tags = [], video_id} = this.props.metadata;
    const {videoRecommendations, page} = this.props;
    const adSize = this.determineAdTagSize({height: mediaHeight, width: mediaWidth});
    const hasRecommendations =
      videoRecommendations && videoRecommendations.length && page?.id !== 'video-landing-page';
    const video = id || video_id;
    const pageTags = page.tags
      .toLowerCase()
      .replace(/_/g, '-')
      .split(',');
    const sanitizedTitle = sanitizeTitle(title);
    const labels = [
      ...new Set([
        // Combine unique tags (page + video + recommendation)
        ...(hasRecommendations ? ['recommended-video'] : []),
        ...tags,
        ...pageTags,
      ]),
    ].join(',');
    const videoTags = [labels.replace(/-/g, '_'), sanitizedTitle];
    const buzz = (global.utag_data && global.utag_data.buzz) || '';
    const site = page.site;
    const league = page?.league;
    const wmukid = StorageAPI.get('datid', true) || StorageAPI.get('WMUKID_STABLE', true);
    const wmukQueryString = wmukid ? `&wmuk=${wmukid}` : null;

    return {
      ad_rule: 1,
      ciu_szs: '3x3,300x250,320x50,450x1,728x90,970x66,970x250,1280x100',
      correlator: '#{now}',
      cust_params: `adset=undefined&app=false&article=${
        page.article
      }&section=${site}&labels=${encodeURIComponent(
        labels
      )}&social=false&tags=${videoTags}&team=${global.encodeURIComponent(
        page.team
      )}&title=${global.encodeURIComponent(
        title
      )}&video=${video}&pg=video&buzz=${buzz}${wmukQueryString || ''}`,
      env: 'vp',
      gdfp_req: 1,
      impl: 's',
      iu: `/8663477/BR/${league || 'ROS'}/video`, //ROS (run of sight) string should be used when on video player page since there is no league
      output: 'xml_vast2',
      plcmt: hasRecommendations ? '2' : '1',
      ppid: wmukid,
      scor: '#{now}',
      sz: adSize,
      unviewed_position_start: 1,
      url: global.encodeURIComponent(global.location.href),
      vpmute: this.state.isMuted ? 1 : 0,
    };
  }

  scheduleAds() {
    const adParams = this.getAdParams();

    // We currently only schedule a pre-roll ad
    this.state.player.ads.schedule({
      tag: {
        url: `https://pubads.g.doubleclick.net/gampad/ads?${buildQueryString(adParams)}`,
        type: 'vast',
        persistent: false,
      },
      id: 'preRollAd',
      position: 'pre',
    });
  }

  // TODO: refactor getter methods should only return values based off component state
  get recommendedOverlayComponents() {
    const rightArrow = new playerui.Button({cssClasses: ['right-arrow']});
    const leftArrow = new playerui.Button({cssClasses: ['left-arrow']});
    const replayButton = new playerui.Button({cssClasses: ['replay']});
    const recommendation = new playerui.Container({cssClass: ['recommended-videos']});
    const videoPlayButton = new playerui.Button({cssClasses: ['video-play-button']});
    const closeButton = new playerui.Button({cssClasses: ['close']});

    closeButton.onClick.subscribe(() => {
      this.clearCountdownTimer();
      this.removeRecommendedOverlays();
      this.updateUIManager();
      this.setState({showVideoPlayer: false});
    });

    replayButton.onClick.subscribe(() => {
      this.clearCountdownTimer();
      this.loadMedia();
      this.updateUIManager();
    });

    rightArrow.onClick.subscribe(() => {
      this.shiftRight();
    });

    leftArrow.onClick.subscribe(() => {
      this.shiftLeft();
    });

    this.state.remainingRecommendedVideos.forEach((video, index) => {
      const videoWrapper = new playerui.Container({cssClasses: ['video-wrapper']});
      const videoItem = new playerui.Container({
        cssClasses: ['video-item', `video-item-${index + 1}`],
      });

      if (index === 0) {
        videoItem.addComponent(videoPlayButton);
      }

      // We need the dom element to add dynamic styles
      const videoItemElement = videoItem.getDomElement().elements[0];
      const thumbnail_url = video.thumbnail_url
        ? video.thumbnail_url
        : video.metadata.thumbnail_url;
      if (videoItemElement) {
        videoItemElement.style.backgroundImage = `url(${getArticleThumb(thumbnail_url, {w: 200})})`;
      }
      if (index === 0) {
        addClass(videoItemElement, 'ready-to-play');
      }

      videoItemElement.onclick = () => {
        this.playRecommendedVideo(video);
        this.setState({progressType: 'manual'});
      };

      const videoTitle = new playerui.Label({
        text: video.title ? video.title : video.metadata.title,
        cssClasses: ['video-title'],
      });

      videoWrapper.addComponent(videoItem);
      videoWrapper.addComponent(videoTitle);
      recommendation.addComponent(videoWrapper);
    });
    return {
      closeButton,
      leftArrow,
      recommendation,
      replayButton,
      rightArrow,
    };
  }

  recommmendationOverlay(cssClass) {
    const {
      ui: {isMobileDevice},
      metadata: {title},
    } = this.props;
    const {
      closeButton,
      leftArrow,
      recommendation,
      replayButton,
      rightArrow,
    } = this.recommendedOverlayComponents;
    const recommmendationOverlay = new playerui.RecommendationOverlay({
      components: [
        new playerui.Container({
          components: [
            new playerui.Label({
              text: title,
              cssClasses: ['recomended-overlay-title'],
            }),
            new playerui.Container({
              components: [replayButton, ...(isMobileDevice ? [closeButton] : [])],
            }),
          ],
          cssClass: 'header',
        }),
        new playerui.Label({cssClasses: ['carousel-header']}),
        recommendation,
        new playerui.Container({
          components: [leftArrow],
          cssClasses: ['left-arrow-container', `${cssClass}`],
        }),

        ...(this.state.displayRightArrow
          ? [
              new playerui.Container({
                components: [rightArrow],
                cssClasses: ['right-arrow-container', `${cssClass}`],
              }),
            ]
          : []),
      ],
      cssClasses: [`${cssClass}`],
    });
    recommmendationOverlay.replayButton = null;
    return recommmendationOverlay;
  }

  updateUIManager() {
    const {player, remainingRecommendedVideos} = this.state;
    const myUiConfig = {
      recommendations: remainingRecommendedVideos,
    };

    // Custom UI elements
    const ccToggleButton = new playerui.ToggleButton({
      cssClass: 'cc-togglebutton',
    });
    ccToggleButton.onToggleOn.subscribe(() => {
      player.subtitles.enable(closedCaptionId);
      this.setState({
        ...this.state,
        isCCEnabled: true,
      });
    });
    ccToggleButton.onToggleOff.subscribe(() => {
      player.subtitles.disable(closedCaptionId);
      this.setState({
        ...this.state,
        isCCEnabled: false,
      });
    });
    ccToggleButton.onClick.subscribe(() => {
      ccToggleButton.toggle();
    });

    // Custom UIs
    const mobileUI = new playerui.UIContainer({
      hideDelay: defaultHideDelay,
      components: [
        new playerui.SubtitleOverlay(),
        new playerui.BufferingOverlay(),
        new playerui.HugeReplayButton(),
        new playerui.PlaybackToggleOverlay(),
        new playerui.ControlBar({
          components: [
            new playerui.Container({
              components: [
                new playerui.VolumeToggleButton(),
                new playerui.Spacer(),
                new playerui.PlaybackTimeLabel({
                  timeLabelMode: playerui.PlaybackTimeLabelMode.RemainingTime,
                  cssClasses: ['text-right', 'remaining-time'],
                }),
                ...(this.props.metadata.cc_url ? [ccToggleButton] : []),
                new playerui.FullscreenToggleButton(),
              ],
              cssClasses: ['controlbar-bottom'],
            }),
          ],
        }),
      ],
    });

    const adsUI = new playerui.UIContainer({
      hideDelay: -1, // UI is always visible
      components: [
        new playerui.BufferingOverlay(),
        new playerui.ControlBar({
          components: [
            new playerui.Container({
              components: [
                new playerui.AdMessageLabel({text: 'Ad will end in {remainingTime%mm:ss}'}),
                new playerui.Spacer(),
                new playerui.VolumeToggleButton(),
                new playerui.FullscreenToggleButton(),
              ],
              cssClasses: ['controlbar-bottom'],
            }),
          ],
        }),
      ],
      cssClasses: ['ui-skin-ads'],
    });

    const mobileLandscapeUI = new playerui.UIContainer({
      hideDelay: defaultHideDelay,
      components: [
        new playerui.SubtitleOverlay(),
        new playerui.BufferingOverlay(),
        new playerui.HugeReplayButton(),
        new playerui.PlaybackToggleOverlay(),
        ...(this.hasMoreRecommendations ? [this.recommmendationOverlay('inline')] : []),
        new playerui.ControlBar({
          components: [
            new playerui.Container({
              components: [
                new playerui.VolumeToggleButton(),
                new playerui.Spacer(),
                new playerui.PlaybackTimeLabel({
                  timeLabelMode: playerui.PlaybackTimeLabelMode.RemainingTime,
                  cssClasses: ['text-right', 'remaining-time'],
                }),
                ...(this.props.metadata.cc_url ? [ccToggleButton] : []),
                new playerui.FullscreenToggleButton(),
              ],
              cssClasses: ['controlbar-bottom'],
            }),
          ],
        }),
      ],
    });

    const desktopFullscreenUI = new playerui.UIContainer({
      hideDelay: defaultHideDelay,
      components: [
        new playerui.SubtitleOverlay(),
        new playerui.BufferingOverlay(),
        new playerui.HugeReplayButton(),
        new playerui.PlaybackToggleOverlay(),
        ...(this.hasMoreRecommendations ? [this.recommmendationOverlay('fullscreen-desktop')] : []),
        new playerui.ControlBar({
          components: [
            new playerui.Container({
              components: [
                new playerui.PlaybackToggleButton(),
                new playerui.PlaybackTimeLabel({
                  timeLabelMode: playerui.PlaybackTimeLabelMode.CurrentTime,
                  hideInLivePlayback: true,
                }),
                new playerui.SeekBar(),
                new playerui.PlaybackTimeLabel({
                  timeLabelMode: playerui.PlaybackTimeLabelMode.TotalTime,
                  cssClasses: ['text-right'],
                }),
                new playerui.VolumeToggleButton(),
                ...(this.props.metadata.cc_url ? [ccToggleButton] : []),
                new playerui.FullscreenToggleButton(),
              ],
              cssClasses: ['controlbar-bottom'],
            }),
          ],
        }),
      ],
    });

    const mobilePortraitFullscreenUI = new playerui.UIContainer({
      hideDelay: defaultHideDelay,
      components: [
        new playerui.SubtitleOverlay(),
        new playerui.BufferingOverlay(),
        new playerui.HugeReplayButton(),
        new playerui.PlaybackToggleOverlay(),
        new playerui.ControlBar({
          components: [
            new playerui.Container({
              components: [
                new playerui.PlaybackToggleButton(),
                new playerui.PlaybackTimeLabel({
                  timeLabelMode: playerui.PlaybackTimeLabelMode.CurrentTime,
                  hideInLivePlayback: true,
                }),
                new playerui.SeekBar(),
                new playerui.PlaybackTimeLabel({
                  timeLabelMode: playerui.PlaybackTimeLabelMode.TotalTime,
                  cssClasses: ['text-right'],
                }),
                new playerui.VolumeToggleButton(),
                ...(this.props.metadata.cc_url ? [ccToggleButton] : []),
                new playerui.FullscreenToggleButton(),
              ],
              cssClasses: ['controlbar-bottom'],
            }),
          ],
        }),
      ],
    });

    const mobileLandscapeFullscreenUI = new playerui.UIContainer({
      hideDelay: defaultHideDelay,
      components: [
        new playerui.SubtitleOverlay(),
        new playerui.BufferingOverlay(),
        new playerui.HugeReplayButton(),
        new playerui.PlaybackToggleOverlay(),
        ...(this.hasMoreRecommendations
          ? [this.recommmendationOverlay('fullscreen-landscape')]
          : []),
        new playerui.ControlBar({
          components: [
            new playerui.Container({
              components: [
                new playerui.PlaybackToggleButton(),
                new playerui.PlaybackTimeLabel({
                  timeLabelMode: playerui.PlaybackTimeLabelMode.CurrentTime,
                  hideInLivePlayback: true,
                }),
                new playerui.SeekBar(),
                new playerui.PlaybackTimeLabel({
                  timeLabelMode: playerui.PlaybackTimeLabelMode.TotalTime,
                  cssClasses: ['text-right'],
                }),
                new playerui.VolumeToggleButton(),
                ...(this.props.metadata.cc_url ? [ccToggleButton] : []),
                new playerui.FullscreenToggleButton(),
              ],
              cssClasses: ['controlbar-bottom'],
            }),
          ],
        }),
      ],
    });

    const defaultUI = new playerui.UIContainer({
      hideDelay: defaultHideDelay,
      components: [
        new playerui.SubtitleOverlay(),
        new playerui.BufferingOverlay(),
        new playerui.HugeReplayButton(),
        new playerui.PlaybackToggleOverlay(),
        ...(this.hasMoreRecommendations ? [this.recommmendationOverlay('inline')] : []),
        new playerui.ControlBar({
          components: [
            new playerui.Container({
              components: [
                new playerui.PlaybackToggleButton(),
                new playerui.PlaybackTimeLabel({
                  timeLabelMode: playerui.PlaybackTimeLabelMode.CurrentTime,
                  hideInLivePlayback: true,
                }),
                new playerui.SeekBar(),
                new playerui.PlaybackTimeLabel({
                  timeLabelMode: playerui.PlaybackTimeLabelMode.TotalTime,
                  cssClasses: ['text-right'],
                }),
                new playerui.VolumeToggleButton(),
                ...(this.props.metadata.cc_url ? [ccToggleButton] : []),
                new playerui.FullscreenToggleButton(),
              ],
              cssClasses: ['controlbar-bottom'],
            }),
          ],
        }),
      ],
    });

    const livePlayerUI = new playerui.UIContainer({
      hideDelay: -1, // UI is always visible
      components: [
        new playerui.SubtitleOverlay(),
        new playerui.BufferingOverlay(),
        // new playerui.PlaybackToggleOverlay(),
        new playerui.ControlBar({
          components: [
            new playerui.Container({
              components: [
                new playerui.VolumeToggleButton(),
                new playerui.Spacer(),
                new playerui.PlaybackTimeLabel({
                  timeLabelMode: playerui.PlaybackTimeLabelMode.RemainingTime,
                  cssClasses: ['text-right', 'remaining-time'],
                }),
                ...(this.props.metadata.cc_url ? [ccToggleButton] : []),
                new playerui.FullscreenToggleButton(),
              ],
              cssClasses: ['controlbar-bottom'],
            }),
          ],
        }),
      ],
    });

    // UI manager and conditions for each UI
    this.myUiManager = new playerui.UIManager(
      player,
      [
        {
          ui: adsUI,
          condition: ({isAd}) => isAd,
        },
        {
          ui: livePlayerUI,
          condition: () => this.props.isLiveVideo,
        },
        {
          ui: mobileLandscapeUI,
          condition: ({isMobile, isFullscreen, width}) => {
            this.videoWidth = width;
            const isPortraitOrientation =
              global.matchMedia && global.matchMedia('(orientation: portrait)').matches;
            return isMobile && !isPortraitOrientation && !isFullscreen;
          },
        },
        {
          ui: mobileUI,
          condition: ({isMobile, isFullscreen, width}) => {
            return (isMobile && !isFullscreen) || width <= 320;
          },
        },
        {
          ui: desktopFullscreenUI,
          condition: ({isMobile, isFullscreen, width}) => {
            return (!isMobile && isFullscreen) || (isFullscreen && width >= 1024);
          },
        },
        {
          ui: mobileLandscapeFullscreenUI,
          condition: ({isMobile, isFullscreen}) => {
            const isPortraitOrientation =
              global.matchMedia && global.matchMedia('(orientation: portrait)').matches;
            return isMobile && !isPortraitOrientation && isFullscreen;
          },
        },
        {
          ui: mobilePortraitFullscreenUI,
          condition: ({isMobile, isFullscreen}) => {
            const isPortraitOrientation =
              global.matchMedia && global.matchMedia('(orientation: portrait)').matches;
            return isMobile && isPortraitOrientation && isFullscreen;
          },
        },
        {
          ui: defaultUI,
        },
      ],
      myUiConfig
    );
  }

  onMediaError() {
    logger.error('Error: Unable to load video player media source');
  }

  onMediaLoaded() {
    const {player} = this.state;
    const {cc_url} = this.props.metadata;
    const {startSeconds} = this.props;

    if (startSeconds) player.seek(startSeconds);

    // Display ads based on tealimu flag
    if (global.videoAds && !this.props.isLiveVideo) {
      this.scheduleAds();
    }

    // Display subtitles when available
    if (cc_url) {
      player.subtitles.add({
        id: closedCaptionId,
        enabled: false,
        lang: closedCaptionId,
        label: 'English',
        url: cc_url,
        kind: 'captions',
      });
    }
  }

  loadMedia() {
    const {metadata, hlsMediaUrl, thumbnail} = this.props;
    const {source, mp4_url, mp4, thumbnail_url, title} = metadata;
    const {player} = this.state;

    //Get the time query parameter from the URL if there is one.
    //This is to allow Google Search to add 'Key Moments' to video search results
    const queryParams = new URLSearchParams(window.location.search);
    const startTime = queryParams.get('t');
    const media = {
      title,
      hls: hlsMediaUrl,
      progressive: mp4_url || mp4 || (source && source[1].src),
      poster: getArticleThumb(thumbnail || thumbnail_url, {w: 1440}),
      options: {
        startTime,
      },
    };

    // Reset player error state
    this.setState({
      ...this.state,
      errorState: false,
    });

    // This shouldn't be needed after Bitmovin fixes their player, but it is for now
    // Additionally unload doesn't seem chainable so we need to call it before load
    player.unload();

    player
      .load(media)
      .then(this.onMediaLoaded, this.onMediaError)
      .catch(this.onMediaError);
  }

  setupPlayer() {
    const {Player} = global.VidBitmovin;
    const {muted, autoplay} = this.props;
    const preload = false;
    let adsManager = null;

    this._addedPlayer = true;

    // New Bitmovin player instance with config
    const videoPlayer = new Player(this._video, {
      key: PLAYER_KEY,
      playback: {
        muted: autoplay || muted,
        autoplay,
      },
      adaptation: {
        desktop: {preload},
        mobile: {preload},
      },
      advertising: {
        adBreaks: [
          {
            position: 'pre',
            tag: {
              type: 'vast',
            },
          },
        ],
        beforeInitialization: (imaSdkSettings) => {
          imaSdkSettings.setDisableCustomPlaybackForIOS10Plus(true);
        },
        onAdsManagerAvailable(AdsManager) {
          adsManager = AdsManager;
          initializeIASImaSDKAdapter(adsManager);
        },
      },
      events: {
        adstarted() {
          adsManager.setVolume(0);
        },
      },
      // Required for showing ads properly
      ui: false,
    });

    // Track conviva analytics based on tealimu flag
    if (global.videoAnalytics) {
      // A ConvivaAnalytics instance is always tied to one player instance
      this.convivaVideoAnalytics = new global.VidConvivaAnalytics(videoPlayer, CONVIVA_KEY, {
        debugLoggingEnabled: process.env.NODE_ENV !== 'production',
        gatewayUrl: CONVIVA_URL,
      });
      this.convivaVideoAnalytics?.updateContentMetadata(generateConvivaConfig(this.props));
      this.convivaAdAnalytics = global.Conviva.Analytics.buildAdAnalytics(
        this.convivaVideoAnalytics?.convivaVideoAnalytics
      );
    }

    // Save player instance reference
    this.setState(
      {
        ...this.state,
        player: videoPlayer,
      },
      () => {
        // Update player UI based on player state
        this.updateUIManager();

        // Update player reference for use outside this component
        this.props.updateVideoPlayerReference(videoPlayer);

        this.addEventListeners(videoPlayer);
        this.loadMedia();
      }
    );
  }

  destroyPlayer() {
    const player = this.state.player;

    if (player !== null) {
      player.destroy();
      this.setState({
        ...this.state,
        player: null,
      });

      this.props.updateVideoPlayerReference(null);
    }
  }

  onError() {
    this.setState({
      ...this.state,
      errorState: true,
    });
    this.props.trackEvent({
      ...this.sharedMediaAttributes(),
      error_time: this.state.player.getCurrentTime() || 0,
      tag_manager_event: 'content_play_error',
    });
    if (typeof this.props.onVideoError === 'function') this.props.onVideoError();
  }

  onStart() {
    this.onPlayStateChange();
    this.props.trackEvent({
      ...this.sharedMediaAttributes(),
      ...this.pageSpecificAttributes(this.props.page.type),
      tag_manager_event: 'content_play',
    });

    this.props.trackEvent({
      tag_manager_event: 'content_selected',
      mediaID: this.props.mediaId,
      urlHash: this.props.ui?.url,
      screen: this.props.page?.type,
      contentType: 'video',
      springType: this.props.hasRecommendations ? 'recommended_video' : 'stream',
      title: this.props.metadata?.title,
    });

    if (this.state.currentAdBreak) {
      this.convivaAdAnalytics?.reportAdPlayerEvent(global.Conviva.Constants.Events.USER_WAIT_ENDED);
    }
  }

  updateShiftValues() {
    const {player} = this.state;
    const {
      ui: {isMobileDevice},
    } = this.props;
    const hasRequiredWidth = global.matchMedia && global.matchMedia('(min-width: 1024px)').matches;
    const isFullscreen = player.getViewMode() === 'fullscreen';

    if ((!isMobileDevice && isFullscreen) || (isFullscreen && hasRequiredWidth)) {
      this.increment = 97;
      this.gutters = -5;
    } else {
      this.increment = 93;
      this.gutters = 2;
    }
  }

  shiftLeft() {
    this.updateShiftValues();
    // if we're at the start of the carousel
    if (this.state.position < 0) {
      const newPosition = this.state.position + this.increment;
      this.shiftPlaylist(newPosition);
    }

    this.clearCountdownTimer();
    this.setState({countdownTimer: 0, remainingTime: 0});
  }

  shiftRight() {
    this.updateShiftValues();
    // if we're already at the end
    if (this.state.position > -this.videoPlaylistWidth) {
      const newPosition = this.state.position - this.increment;
      this.shiftPlaylist(newPosition);
    }

    this.clearCountdownTimer();
    this.setState({countdownTimer: 0, remainingTime: 0});
  }

  getArrowWidth(element) {
    const isInlineElement = hasClass(element, 'bmpui-inline');
    const isFullscreenLandscape = hasClass(element, 'bmpui-fullscreen-landscape');
    const hasRequiredWidth = global.matchMedia && global.matchMedia('(min-width: 1024px)').matches;
    const userSmallerWidth = isInlineElement || (isFullscreenLandscape && !hasRequiredWidth);
    return userSmallerWidth ? '25px' : '45px';
  }

  setStyle(elements, property, value) {
    elements.forEach((element) => {
      if (!value && property === 'width') {
        element.style[property] = this.getArrowWidth(element);
      } else {
        element.style[property] = value;
      }
    });
  }

  setVideoWidth() {
    const videoWrapperList = [...global.document.querySelectorAll(`.bmpui-video-wrapper`)];
    videoWrapperList.forEach((wrapper, index) => {
      wrapper.style.width =
        index === 0 ? `${this.videoWidth / 3 - 29.5}px` : `${this.videoWidth / 3 - 26.5}px`;
    });
  }

  shiftPlaylist(position) {
    let newPosition = position;

    // adjusts position so it never goes past the last thumbnail
    if (position < -this.videoPlaylistWidth) {
      newPosition = -this.videoPlaylistWidth + this.gutters;
      // adjusts position to never go past first thumbnail
    } else if (position > 0) {
      newPosition = 0;
    }

    // Updating react State is not suitable for changing an element bitmovin video-player UI
    // so we have to update the DOM for changes in the Videoplayer UI to be effected

    // The queryselectors return maximum array length of 2 (inline and fullsceen)
    // The 2 views should be updated on every arrow click

    const videos = [...document.querySelectorAll('.bmpui-recommended-videos')];
    this.setStyle(videos, 'left', `${newPosition}%`);

    const leftArrowContainer = [...document.querySelectorAll('.bmpui-left-arrow-container')];
    const rightArrowContainer = [...document.querySelectorAll('.bmpui-right-arrow-container')];
    const leftArrow = [...document.querySelectorAll('.bmpui-left-arrow')];
    const rightArrow = [...document.querySelectorAll('.bmpui-right-arrow')];

    if (newPosition !== 0) {
      this.setStyle(leftArrowContainer, 'width', '');
      this.setStyle(leftArrow, 'visibility', 'visible');
    } else {
      this.setStyle(leftArrowContainer, 'width', '0');
      this.setStyle(leftArrow, 'visibility', 'hidden');
    }

    if (newPosition !== -this.videoPlaylistWidth + this.gutters) {
      this.setStyle(rightArrow, 'visibility', 'visible');
      this.setStyle(rightArrowContainer, 'width', '');
    } else {
      this.setStyle(rightArrowContainer, 'width', '0');
      this.setStyle(rightArrow, 'visibility', 'hidden');
    }

    this.setState({
      position: newPosition,
    });
  }

  playRecommendedVideo(video) {
    this.setState({recomendedVideoEnded: false});
    const contentPlacement = this.props.videoRecommendations.findIndex(
      (recomendedVideo) => recomendedVideo.id === video.id
    );
    this.setState({contentPlacement});
    this.props.resetSelectedVideo();
    if (video.metadata) {
      //metadata exists; no need for extra call to vid.br api
      this.props.playSpecificVideo(video.metadata.video_id, false);
    } else {
      this.props.playSpecificVideo(video.id, true);
    }
    this.clearCountdownTimer();
    this.setRemainingRecommendedVideos(video.id);
  }

  clearCountdownTimer() {
    clearInterval(this.state.countdownTimer);
    this.setState({
      remainingTime: 0,
      countdownTimer: 1,
    });
  }

  setRecommendationTimerText() {
    let remainingTime = 5;
    const countdownTimer = setInterval(() => {
      const remainingTimeText = this.state.remainingTime
        ? `Up next in ${this.state.remainingTime}...`
        : '';

      const displayMessage = this.state.countdownTimer ? remainingTimeText : 'Related Videos';
      const timerLabel = [...document.querySelectorAll('.bmpui-carousel-header')];

      if (timerLabel.length) {
        timerLabel.forEach((label) => {
          label.innerHTML = displayMessage;
        });
      }
      this.setState({countdownTimer, remainingTime: remainingTime--}, () => {
        if (this.state.remainingTime < 0) {
          const video = this.state.remainingRecommendedVideos[0];
          this.playRecommendedVideo(video);
          this.setState({progressType: 'auto_advance'});
        }
      });
    }, 1000);
  }

  onEnd() {
    this.props.onVideoEnded();
    this.trackContentPlaySummary();
    if (this.props.videoRecommendations && this.props.videoRecommendations.length) {
      this.setState({recomendedVideoEnded: true});
      this.playNextRecommendedVideo();
    }
  }

  onPlaySpecificVideo() {
    this.sendVideoPlaySummary();
    this.loadMedia();
    this.onPlayStateChange();
  }

  onPause() {
    this.onPlayStateChange();
    this.props.trackEvent({
      ...this.sharedMediaAttributes(),
      player_time: this.state.player.getCurrentTime(),
      tag_manager_event: 'content_pause',
    });
    if (this.state.currentAdBreak) {
      this.convivaAdAnalytics?.reportAdPlayerEvent(
        global.Conviva.Constants.Events.USER_WAIT_STARTED
      );
    }
  }

  onPlayStateChange() {
    // Hide player when playing video (from HP redesign)
    const playerState = this.state.player.isPlaying() ? STATE_PLAYING : 'paused';
    this.props.handleContentBox(playerState);
  }

  onSubtitleEnable() {
    this.props.trackEvent({
      ...this.sharedMediaAttributes(),
      player_time: this.state.player.getCurrentTime(),
      tag_manager_event: 'content_cc',
    });
  }

  onMuted() {
    this.setState({isMuted: true});
    this.props.trackEvent({
      ...this.sharedMediaAttributes(),
      player_time: this.state.player.getCurrentTime(),
      tag_manager_event: 'content_mute',
    });
  }

  onUnMuted() {
    this.setState({isMuted: false});
    this.props.trackEvent({
      ...this.sharedMediaAttributes(),
      player_time: this.state.player.getCurrentTime(),
      tag_manager_event: 'content_unmute',
    });
  }

  onDisplayChange(event) {
    this.clearCountdownTimer();
    if (event.to === 'fullscreen') {
      const isPortraitOrientation =
        global.matchMedia && global.matchMedia('(orientation: portrait)').matches;

      this.props.trackEvent({
        ...this.sharedMediaAttributes(),
        player_time: this.state.player.getCurrentTime(),
        tag_manager_event: 'content_fullscreen',
        orientation: isPortraitOrientation ? 'portrait' : 'landscape',
      });
    }
    if (this.state.recomendedVideoEnded) {
      this.setVideoWidth();
      const videoCarouselHeaders = [...document.querySelectorAll('.bmpui-carousel-header')];
      if (videoCarouselHeaders.length) {
        videoCarouselHeaders.forEach((header) => {
          header.innerHTML = 'Related Videos';
        });
      }
    }
  }

  onAdBreakStarted(adStartEvent) {
    this.setState({
      ...this.state,
      currentAdBreak: adStartEvent.adBreak,
    });
    this.onPlayStateChange();
    this.props.trackEvent({
      ...this.sharedMediaAttributes(),
      ...this.pageSpecificAttributes(this.props.page.type),
      ad_type: 'pre_roll',
      tag_manager_event: 'ad_play',
    });
    this.convivaVideoAnalytics?.reportAdBreakStarted(
      global.Conviva.Constants.AdType.SERVER_SIDE,
      global.Conviva.Constants.AdPlayer.CONTENT
    );
    this.convivaVideoAnalytics?.reportPlaybackEvent(
      global.Conviva.Constants.Events.USER_WAIT_STARTED
    );
  }

  onAdBreakFinished() {
    this.convivaVideoAnalytics?.reportAdBreakEnded?.();
    this.convivaVideoAnalytics?.reportPlaybackEvent(
      global.Conviva.Constants.Events.USER_WAIT_ENDED
    );
    this.setState({
      ...this.state,
      currentAdBreak: null,
    });
  }

  mapAdPosition(adBreak) {
    switch (adBreak?.position) {
      case 'pre':
        return global.Conviva.Constants.AdPosition.PREROLL;
      case 'mid':
        return global.Conviva.Constants.AdPosition.MIDROLL;
      case 'post':
        return global.Conviva.Constants.AdPosition.POSTROLL;
      default:
        return global.Conviva.Constants.AdPosition.PREROLL;
    }
  }

  getConvivaAdInfo(data) {
    const ad = data.ad || {};
    // Based off: https://github.com/TurnerOpenPlatform/top-player/blob/b6a985648ffbf8953f1dcb90cfc9a581b6b88a4b/packages/player-block-shared/src/player/common/analytics/conviva/ConvivaAnalyticsSink.ts#L189
    return {
      'Conviva.applicationName': 'B/R - Web',
      'Conviva.duration': ad.duration,
      'Conviva.streamUrl': ad.mediaFileUrl,
      'Conviva.assetName': ad.data?.adTitle || 'NA',
      'Conviva.streamType': this.props.isLiveVideo ? 'LIVE' : 'VOD',
      'Conviva.viewerId': this.props.user?.id || 'Anonymous',
      'c3.ad.id': ad.id || 'NA',
      'c3.ad.system': ad.adSystem || 'NA',
      'c3.ad.isSlate': ad.isFiller && ad.isFiller.toString(),
      'c3.ad.position': this.mapAdPosition(this.state.currentAdBreak),
      'c3.ad.adStitcher': 'YoSpace',
      'c3.ad.firstAdSystem': ad.firstAdSystem || ad.data?.adSystem?.name,
      'c3.ad.firstAdId': ad.firstAdId || ad.id,
      'c3.ad.firstCreativeId': ad.firstCreativeId || ad.creativeId,
      'c3.ad.creativeId': ad.data?.creative?.id,
      'c3.ad.sequence': ad.sequence ? ad.sequence.toString() : 'NA',
      'c3.ad.advertiser': ad.advertiser,
      contentAssetName: this.props.metadata?.title || 'NA',
    };
  }

  onAdManifestLoaded(data) {
    const adInfo = this.getConvivaAdInfo(data);
    this.convivaAdAnalytics?.reportAdLoaded(adInfo);
  }

  onAdStart(data) {
    const ad = data.ad || {};
    const adInfo = this.getConvivaAdInfo(data);
    this.convivaAdAnalytics?.reportAdStarted(adInfo);
    this.convivaAdAnalytics?.reportAdMetric(
      global.Conviva.Constants.Playback.PLAYER_STATE,
      global.Conviva.Constants.PlayerState.PLAYING
    );
    if (ad.data?.bitrate) {
      this.convivaAdAnalytics?.reportAdMetric(
        global.Conviva.Constants.Playback.BITRATE,
        ad.data.bitrate
      );
    }
  }

  onAdSkipped() {
    this.convivaAdAnalytics?.reportAdSkipped();
    this.convivaAdAnalytics?.reportAdMetric(
      global.Conviva.Constants.Playback.PLAYER_STATE,
      global.Conviva.Constants.PlayerState.STOPPED
    );
  }

  onAdError(event) {
    logger.error(`Error loading ad: ${event.message}`);
    this.convivaAdAnalytics?.reportAdFailed(event.message);
  }

  onAdEnd() {
    this.trackAdPlaySummary();
    this.convivaAdAnalytics?.reportAdEnded();
  }

  onUnload() {
    if (this.state.player) {
      this.sendVideoPlaySummary();
      this.sendPlaylistSummary();
      this.convivaAdAnalytics?.reportAdEnded();
      this.convivaVideoAnalytics?.reportAdBreakEnded?.();
      this.setState({
        ...this.state,
        currentAdBreak: null,
      });
      this.destroyPlayer();
    }
  }

  onTimeChanged(event) {
    const hasRecommendations =
      this.props.videoRecommendations && this.props.videoRecommendations.length;
    const duration = Math.round(event.time);
    const videoId = this.props.metadata.id;
    if (hasRecommendations) {
      if (duration === THREE_SECOND_MARK_DURATION) {
        const viewedVideos = this.state.viewedVideos;
        if (!this.state.viewedVideos.includes(videoId)) {
          this.setState({
            viewedVideos: viewedVideos.concat([videoId]),
          });
        }
      }

      const watchedVideos = this.state.watchedVideos.slice();
      const videoIndex = watchedVideos.findIndex((video) => video.id === videoId);
      if (videoIndex === -1) {
        this.setState({watchedVideos: watchedVideos.concat([{id: videoId, duration}])});
      } else {
        watchedVideos[videoIndex] = {id: videoId, duration};
        this.setState({watchedVideos});
      }
    }
    if (duration === THREE_SECOND_MARK_DURATION && !this.state.impressionTrackersFired) {
      // fire impression trackers
      this.setState({
        impressionTrackersFired: true,
      });
    }
  }

  trackAdPlaySummary() {
    const activeAd = this.getActiveAd();
    const adDuration = activeAd && activeAd.duration;
    this.props.trackEvent({
      ...this.sharedMediaAttributes(),
      ...this.pageSpecificAttributes(this.props.page.type),
      ad_type: 'pre_roll',
      completion_interval: this.completionInterval(),
      content_duration: adDuration || 0,
      tag_manager_event: 'ad_play_summary',
    });
  }

  get contentScore() {
    const {metadata, videoRecommendations} = this.props;
    const hasRecommendations = videoRecommendations && videoRecommendations.length;
    if (!hasRecommendations) {
      return {};
    }
    const videoId = metadata.id || metadata.video_id;
    const video = videoRecommendations.find((video) => video.video_id === videoId);
    const score = (video && video.score) || '';
    return {
      content_score: score,
    };
  }

  trackContentPlaySummary() {
    const player = this.state.player;
    if (!player || !player.getCurrentTime()) {
      return;
    }
    this.props.trackEvent({
      ...this.sharedMediaAttributes(),
      ...this.pageSpecificAttributes(this.props.page.type),
      captions_enabled: this.state.isCCEnabled,
      completion_interval: this.completionInterval(),
      content_duration: player.getDuration(),
      fullscreen_enabled: player.getViewMode() === 'fullscreen', //add var fullscreenEnabled in tagManager
      player_time: player.getCurrentTime(),
      sound_enabled: !player.isMuted(),
      tag_manager_event: 'content_play_summary',
    });
  }

  sendPlaylistSummary() {
    const totalTimeSpent = this.state.watchedVideos.reduce((accumulator, video) => {
      return accumulator + video.duration;
    }, 0);

    this.props.trackEvent({
      author: this.props.author.id,
      title: this.props.title,
      total_videos_viewed: this.state.viewedVideos.length,
      total_videos_available: this.props.videoRecommendations.length,
      total_time_spent: totalTimeSpent,
      experiment_type: this.props.experimentType,
      tag_manager_event: 'video_playlist_summary',
    });
  }

  sendVideoPlaySummary() {
    // Only send video summary if the video has started
    // This prevents page refreshes where the videoPlayer has loaded but not started
    // from sending analytics data
    const player = this.state.player;
    const activeAd = player.ads && player.ads.getActiveAd();
    const adInProgress = activeAd && activeAd.id;
    if (adInProgress) {
      this.trackAdPlaySummary();
    } else if (player.isPaused() || player.isPlaying()) {
      this.trackContentPlaySummary();
    }
  }

  setRemainingRecommendedVideos(videoId) {
    if (this.hasMoreRecommendations) {
      const remainingRecommendedVideos = this.state.remainingRecommendedVideos.filter((video) => {
        return video.id !== videoId;
      });

      this.videoPlaylistWidth = remainingRecommendedVideos.length * this.thumbWidth - 100;
      this.setState({
        remainingRecommendedVideos,
        displayRightArrow: this.videoPlaylistWidth > 0,
        position: 0,
      });
    }
  }

  get hasMoreRecommendations() {
    return this.state.remainingRecommendedVideos && this.state.remainingRecommendedVideos.length;
  }

  removeOverlays(overlays, type) {
    overlays.forEach((overlay, index) => {
      // remove all cloned overlays but leave the last
      if (type === 'cloned') {
        if (index !== overlays.length - 1) {
          overlay.parentNode.removeChild(overlay);
        }
      } else {
        overlay.parentNode.removeChild(overlay);
      }
    });
  }

  // Bitmovin displays multiple recommended overlays when the UI is updated.
  // This function removes duplicated recommended overlays
  removeRecommendedOverlays(type) {
    const fullscreenDesktopOverlays = [
      ...global.document.querySelectorAll(
        '.bmpui-ui-recommendation-overlay.bmpui-recommendations.bmpui-fullscreen-desktop'
      ),
    ];

    const inlineOverlays = [
      ...global.document.querySelectorAll(
        '.bmpui-ui-recommendation-overlay.bmpui-recommendations.bmpui-inline'
      ),
    ];

    const fullscreenLandscapeOverlays = [
      ...global.document.querySelectorAll(
        '.bmpui-ui-recommendation-overlay.bmpui-recommendations.bmpui-fullscreen-landscape'
      ),
    ];

    if (fullscreenDesktopOverlays.length) {
      this.removeOverlays(fullscreenDesktopOverlays, type);
    }

    if (inlineOverlays.length) {
      this.removeOverlays(inlineOverlays, type);
    }

    if (fullscreenLandscapeOverlays.length) {
      this.removeOverlays(fullscreenLandscapeOverlays, type);
    }
  }

  playNextRecommendedVideo() {
    const {
      ui: {isMobileDevice},
    } = this.props;

    const isPortraitOrientation =
      global.matchMedia && global.matchMedia('(orientation: portrait)').matches;

    if (this.hasMoreRecommendations) {
      // Remove cloned overlays
      this.removeRecommendedOverlays('cloned');
      this.setVideoWidth();
      if ((!isPortraitOrientation || !isMobileDevice) && this.props.showPlaylist !== 'side') {
        this.setRecommendationTimerText();
      } else {
        this.removeRecommendedOverlays();
        const video = this.state.remainingRecommendedVideos[0];
        this.playRecommendedVideo(video);
      }
      // There are no more recommended videos so we remove all overlays
    } else {
      this.removeRecommendedOverlays();
      this.sendPlaylistSummary();
    }
  }

  getActiveAd() {
    const player = this.state.player;
    const activeAd = player.ads && player.ads.getActiveAd();
    return activeAd;
  }

  hasImpressionTrackers() {
    const trackers = this.props.metadata.impression_tracking;
    return trackers && trackers.length;
  }

  shouldFireImpressionTrackers() {
    return this.hasImpressionTrackers() && this.state.impressionTrackersFired;
  }

  /**
   * This function fires the impression trackers that
   * are attached to a given video. This is part of the Ad framework.
   */
  fireImpressionTrackers() {
    const trackers = this.props.metadata.impression_tracking;
    return trackers.map((tr, index) => {
      return <iframe key={index} src={tr.url} />;
    });
  }

  onVideoIndicatorClick() {
    this.props.trackEvent({
      tag_manager_event: 'stream_selected',
      screen: 'article',
      springType: 'recommended_video',
    });
  }

  render() {
    if (!this.props.metadata || !this.props.page) {
      return false;
    }
    const {badge, hasRecommendations} = this.props;

    if (!this.state.showVideoPlayer) {
      return false;
    }

    const videoId =
      this.props.trackID || this.props.metadata.video_id || this.props.metadata.video_url;
    const playlist = this.props.playlist || this.props.page.article || 'no-playlist';
    const videoUid = `${playlist}_${videoId}`;
    const classes = classnames({
      molecule: true,
      error: this.state.errorState,
      videoPlayer: true,
      bitmovinPlayer: true,
      active: this.isVideoUnderCursor,
    });
    const content = this.props.isYoutube ? (
      <div className="content" id={`video-${videoUid}`} ref={this.setVideoRef}>
        <YoutubeEmbed
          autoplay={this.props.autoplay}
          id={this.props.metadata.video_id}
          height="100%"
          oneTrustPreferences={this.props.oneTrustPreferences}
          width="100%"
          startSeconds={this.props.startSeconds}
        />
      </div>
    ) : (
      <div id={`video-${videoUid}`} ref={this.setVideoRef} />
    );

    return (
      <>
        <div id={`video-wrapper-${videoUid}`} className={classes}>
          {badge && badge.text ? <Badge {...badge} /> : null}
          <div className="videoHeader">
            <SVG
              type="icon"
              target={this.state.isMuted ? 'muted' : 'volume'}
              onClick={this.volumeToggle}
            />
          </div>
          {content}
          {this.shouldFireImpressionTrackers() ? (
            <div id="trackers" className="impressionTrackers">
              {this.fireImpressionTrackers()}
            </div>
          ) : (
            ''
          )}
        </div>
        {hasRecommendations && this.props.showPlaylist !== 'side' ? (
          <VideoIndicator onVideoIndicatorClick={this.onVideoIndicatorClick} />
        ) : null}
      </>
    );
  }
}

export default VideoPlayer;
