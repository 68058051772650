import {useEffect} from 'react';
import {connect} from 'react-redux';

import * as AnalyticsActions from '../../actions/analyticsActions';
import * as AppActions from '../../actions/appActions';
import {getScreenType} from '../../constants/mParticle';
import {contentTypes} from '../../constants/contentTypes';

let hasYtIframeStartedLoad = false;
const ytSrcs = [];
const ytPlayers = [];

const IframeTracker = ({children, pageType, loadScript, pageSubType, src, trackEvent}) => {
  useEffect(() => {
    if (src && src.includes('youtube.com')) {
      // Youtube IFrame Player API: https://developers.google.com/youtube/iframe_api_reference
      const trackContentPlaySummary = (player) => {
        if (!player) {
          return;
        }
        trackEvent({
          tag_manager_event: 'content_play_summary',
          contentType: contentTypes.YOUTUBE_VIDEO,
          screen: getScreenType(pageType, pageSubType),
          playerTime: player.getCurrentTime(),
          // playerState of 0 means the video ended. We add this logic because when the video enters this state, there is usually 1 second remaining of play time instead of 0.
          // This would result in a completion interval of 99 instead of 100.
          completionInterval:
            player.getPlayerState() === 0
              ? 100
              : Math.floor((player.getCurrentTime() / player.getDuration()) * 100),
        });
      };
      if (!hasYtIframeStartedLoad) {
        loadScript({
          key: 'yt-iframe-api',
          src: 'https://www.youtube.com/iframe_api',
          onLoad: () => {
            global.window.onYouTubeIframeAPIReady = () => {
              ytSrcs.forEach((src) => {
                const onPlayerStateChange = (event) => {
                  if (event.data === 0) {
                    trackContentPlaySummary(event.target);
                  }
                };
                const player = new global.window.YT.Player(src, {
                  events: {
                    onReady: () => {},
                    onStateChange: onPlayerStateChange,
                  },
                });
                ytPlayers.push(player);
              });
              const onBeforeUnload = () => {
                ytPlayers.forEach((player) => {
                  // On page unload, we don't track for the following states:
                  // -1 is video unstarted.
                  // 0 is video ended (already tracked by onStateChange).
                  // 5 is video cued. This state is when video is ready to play but has not been played.
                  if (player.getPlayerState() > 0 && player.getPlayerState() < 5) {
                    trackContentPlaySummary(player);
                  }
                });
                global.removeEventListener('beforeunload', onBeforeUnload);
              };
              global.addEventListener('beforeunload', onBeforeUnload);
            };
          },
        });
      }
      hasYtIframeStartedLoad = true;
      ytSrcs.push(src);
    }
  }, [loadScript, pageSubType, pageType, src, trackEvent]);

  return <>{children}</>;
};

const mapStateToProps = (state) => {
  return {
    pageType: state.ui?.pageType,
    subPageType: state.ui?.subPageType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadScript: (script) => dispatch(AppActions.loadScript(script)),
    trackEvent: (event) => dispatch(AnalyticsActions.trackEvent(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IframeTracker);
