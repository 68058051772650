import StorageAPI from '../../../../apis/storage_api';
import {videoStates} from '../../../../constants/videoStates';
import {videoViewModes} from '../../../../constants/videoViewModes';
import {contentTypes} from '../../../../constants/contentTypes';

const convertToSlug = (str = '') => {
  return str.toLowerCase().replace(/_/g, '-');
};

const getScreenFromURL = (location = '') => {
  const paths = location?.pathname.split('/').filter((path) => path); // last path excluding empty ones
  return paths?.[paths.length - 1] || 'home'; // default to home if no path is found
};

/**
 * Returns viewMode based on parameters. If viewMode exists, we use that to define the returned value.
 * Otherwise, we default to using the isFullScreen parameter
 * TOP Player ViewMode enums: https://topdocs.ngtv.io/api/player/web/enums/ViewMode.html
 * @param {*} viewMode
 * @param {*} isFullScreen
 */
const computeViewMode = (viewMode, isFullScreen) => {
  if (!viewMode) {
    return isFullScreen ? videoViewModes.FULLSCREEN : videoViewModes.INLINE;
  }
  switch (viewMode) {
    case 'remote':
      return videoViewModes.CASTING;
    case 'windowed':
      return videoViewModes.INLINE;
    default:
      return viewMode;
  }
};

export const generateConvivaConfig = (
  {
    contentPlacement,
    isFullScreen,
    isLiveVideo,
    liveEventState,
    liveVideoMetadata,
    metadata = {},
    publishedDate,
    site,
    tags = '',
    title,
    track = {},
    ui = {},
    user = {},
    viewMode,
    league,
    liveVideoOptions = {},
  },
  isForTopPlayer = false
) => {
  const formattedTags = tags
    .split(',')
    .map(convertToSlug)
    .join(',');
  const isTVE = liveVideoOptions?.tveOptions?.isTVE;
  const isTVEFreePreview = liveVideoOptions?.tveOptions?.isTVEFreePreview;
  const isCreators = track.content_type === contentTypes.CREATORS;
  const context = ui.tveAuthContext && ui.tveAuthContext.context;
  const isTVEAuthenticated = context && context.authenticatedState === 'authenticated';
  const wmukid = global.WM && global.WM.PSM.getWMUKID();
  const analytics = metadata.analytics || {};
  const customTags = {
    advertisingId: StorageAPI.get('kxkuid') || '',
    analyticsTags: analytics?.tags?.join(',') || '',
    appName: 'B/R - Web',
    autoplay: track.content?.autoplay,
    brAuthStatus: user?.id ? 'true' : 'false',
    contentPlacement: !isNaN(contentPlacement) && contentPlacement?.toString(),
    contentTags: metadata?.tags?.join(',') || '',
    contentType: track.content_type || '',
    duration: metadata?.duration?.toString() || '',
    highlight_creationTimePeriod: analytics.creation_time_period || '',
    highlight_publishMethod: analytics.publish_method || '',
    highlight_rating: analytics.rating?.toString() || '',
    highlight_ruleId: analytics.rule_id || '',
    highlight_ruleName: analytics.rule_name || '',
    highlight_videoType: analytics.video_type || '',
    kruxId: StorageAPI.get('kxkuid') || '',
    league: league?.acronym || analytics?.league?.acronym || analytics?.leagues?.[0] || '',
    published_at: analytics.published_at || publishedDate,
    screen: getScreenFromURL(global.location),
    streamName: convertToSlug(site) || track?.tag?.unique_name || analytics.stream,
    tags: formattedTags,
    title: title || analytics.title,
    type: 'Bleacher Report',
    video_id: analytics.video_id?.toString() || 0,
    wmukid,
  };

  const config = {
    adInsights: true,
    applicationName: 'B/R - Web',
    customerKey: process.env.CONVIVA_CUSTOMER_KEY,
    metadata: {
      deviceType: ui.userAgent && ui.userAgent.os ? ui.userAgent.os.family : false,
      viewerId: user.trackingId || user.id,
    },
    serviceURL: process.env.CONVIVA_SERVICE_URL,
    touchstoneUrl: process.env.CONVIVA_SERVICE_URL,
    viewerId: StorageAPI.get('portmeirion_id', true) || user.trackingId || user.id,
  };

  if (isLiveVideo) {
    config.assetName = analytics.title;
    customTags.contentId = analytics.id;
    customTags.contentType = liveEventState === videoStates.REPLAY ? 'VOD' : liveEventState;
    customTags.liveVideoType =
      metadata?.live_video_type || liveVideoMetadata?.[metadata.live_event_id]?.type || '';
  }

  if (isTVE) {
    customTags.authState =
      isTVEFreePreview && !isTVEAuthenticated
        ? 'Free Preview'
        : isTVEAuthenticated
        ? 'Authenticated'
        : 'Not Authenticated';
    customTags.adobeId = '17289258468614048784104559592910652050';
    customTags.affiliate =
      isTVEFreePreview && !isTVEAuthenticated
        ? 'Temp Pass'
        : isTVEAuthenticated
        ? context.authenticatedProvider.displayName
        : '';
    customTags.mvpdId = isTVEAuthenticated ? context.authenticatedProvider.id : 'no value';
  }

  if (isCreators) {
    config.assetName = track.content?.metadata?.title
      ? track.content?.metadata?.title
      : track.content?.title;
    customTags.assetName = track.content?.metadata?.title
      ? track.content?.metadata?.title
      : track.content?.title;
    customTags.contentId = track.id_str;
    customTags.liveVideoType = 'free';
    customTags.creatorEvent_id = track?.permalink?.replace('/', '');
    customTags.video_id = track.content?.media_id;
    customTags.isLive = liveEventState === videoStates.LIVE;
    customTags.viewerId = StorageAPI.get('portmeirion_id', true) || user?.trackingId || user?.id;
    customTags.viewMode = 'inline'; // Default to be overwritten via user interation (fullscreen)
  }

  // We only send back a viewMode if only viewMode or isFullScreen are explicitly set
  if (typeof viewMode === 'string' || typeof isFullScreen === 'boolean') {
    customTags.viewMode = computeViewMode(viewMode, isFullScreen);
  }

  if (isForTopPlayer) {
    config.tags = customTags;
  } else {
    customTags.id = analytics.id;
    // Custom tags for Conviva: https://github.com/bitmovin/bitmovin-player-web-analytics-conviva#content-metadata-handling
    config.custom = customTags;
  }

  return config;
};
