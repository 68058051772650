import React from 'react';
import classnames from 'classnames';
import {equals} from 'ramda';
import Iframe from '../../atoms/iframe';
import {buildQueryString} from 'url-params-helper';

import Commentary from '../../atoms/commentary';
import Timestamp from '../../atoms/timestamp';

class YoutubeEmbed extends React.Component {
  shouldComponentUpdate(nextProps) {
    // When OneTrust preferences are set we need to update to load the Youtube video
    if (!equals(this.props.oneTrustPreferences, nextProps.oneTrustPreferences)) {
      return true;
    }
    return false;
  }
  buildSrcQueryString() {
    return buildQueryString({
      autoplay: this.props.autoplay ? '1' : '0',
      cc_load_policy: this.props.captions ? '1' : '0',
      enablejsapi: '1',
      playsinline: this.props.playsInline ? '1' : '0',
      rel: this.props.related ? '1' : '0',
      showinfo: this.props.showinfo ? '1' : '0',
      wmode: 'transparent',
      start: this.props.startSeconds,
    });
  }
  render() {
    const classes = classnames({
      youtubeEmbed: true,
      molecule: true,
    });

    const timestamp = this.props.hasTimestamp ? this.props.timestamp : null;
    const oneTrustPreferences = this.props.oneTrustPreferences;

    // Depending on if a user allows advertising cookies,
    // we use youtube or youtube-nocookie for GDPR compliance
    const youtubeDomain =
      oneTrustPreferences && oneTrustPreferences.advertising ? 'youtube' : 'youtube-nocookie';
    const src = `https://www.${youtubeDomain}.com/embed/${
      this.props.id
    }?${this.buildSrcQueryString()}`;
    return (
      <div className={classes}>
        <Timestamp datetime={timestamp} />
        <Commentary {...this.props.commentary} />
        {oneTrustPreferences ? <Iframe src={src} key={src} height="100%" /> : null}
      </div>
    );
  }
}

YoutubeEmbed.defaultProps = {
  autoplay: false,
  captions: false,
  playsInline: false,
  oneTrustPreferences: false,
  related: false,
  showinfo: false,
  time: false,
};

export default YoutubeEmbed;
