export const AD_PLAY = 'ad_play';
export const AD_PLAY_SUMMARY = 'ad_play_summary';
export const CONTENT_MUTE = 'content_mute';
export const CONTENT_UNMUTE = 'content_unmute';
export const CONTENT_FULLSCREEN = 'content_fullscreen';
export const CONTENT_PLAY_SUMMARY = 'content_play_summary';
export const CONTENT_PLAY = 'content_play';
export const CONTENT_PAUSE = 'content_pause';
export const CONTENT_PLAY_ERROR = 'content_play_error';
export const CONTENT_CC = 'content_cc';
export const CASTING_SUCCESS = 'casting_success';
export const FREE_PREVIEW_EXPIRED = 'free_preview_expired';
