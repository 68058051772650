import {useState, useEffect, useRef, useCallback} from 'react';
import classnames from 'classnames';

const impressionTrackerDelay = 3000;
let asset = null;
const Banner = (props) => {
  const [firedImpressionTracker, setFiredImpressionTracker] = useState(false);
  const [isImageValid, setIsImageValid] = useState(false);
  const timer = useRef(null);

  const {campaign_name, assets, isFullScreen} = props;

  // Free live video events are going to have only one asset
  if (assets && assets.length > 0) {
    asset = {...assets[0]};
  }

  function shouldFireImpressionTrackers() {
    const hasImpressionTrackers = asset.impression_tracking && asset.impression_tracking.length;
    return hasImpressionTrackers && firedImpressionTracker;
  }
  function fireImpressionTrackers() {
    return asset.impression_tracking.map((tr, index) => {
      return <iframe key={`imp_track_${index}`} src={tr} />;
    });
  }

  const startAnimation = useCallback(() => {
    setIsImageValid(true);
    timer.current = setTimeout(() => {
      setFiredImpressionTracker(true);
    }, impressionTrackerDelay);
  }, []);

  useEffect(() => {
    clearTimeout(timer.current);
  }, [isFullScreen]);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
      setIsImageValid(false);
    };
  }, []);

  function openAdvertiserWebsite() {
    if (!firedImpressionTracker) {
      setFiredImpressionTracker(true);
    }
    window.open(asset.target);
  }
  const classes = classnames({
    atom: true,
    sponsoredAd: true,
    animatedAd: isImageValid,
    fullscreen: isFullScreen,
  });
  return (
    asset && (
      <div className={classes}>
        <a onClick={openAdvertiserWebsite}>
          <img alt={`${campaign_name}-logo`} src={asset.image} onLoad={startAnimation} />
        </a>
        {shouldFireImpressionTrackers() && (
          <div id="liveVideoTrackers" className="impressionTrackers">
            {fireImpressionTrackers()}
          </div>
        )}
      </div>
    )
  );
};
Banner.defaultProps = {
  isFullScreen: false,
  campaign_name: '',
  assets: [],
};
export default Banner;
