import React from 'react';
import classnames from 'classnames';
import {addHours, distanceInWordsToNow, distanceInWordsStrict, isPast, format} from 'date-fns';
import {SVG} from '@br/br-components';
import PropTypes from 'prop-types';

class Timestamp extends React.Component {
  static propTypes = {
    dateTimeToCompare: PropTypes.string,
    datetime: PropTypes.string,
    hasClockIcon: PropTypes.bool,
    isStrictWordsTimeDisplay: PropTypes.bool,
    type: PropTypes.string,
  };

  static defaultProps = {
    datetime: '',
    dateTimeToCompare: '',
    hasClockIcon: true,
    isStrictWordsTimeDisplay: false,
    type: '',
  };

  constructor(props) {
    super(props);

    this.isMoreThanAnHourOld = isPast(addHours(props.datetime, 1));

    if (props.datetime) {
      if (this.isMoreThanAnHourOld) {
        this.displayTime = format(this.props.datetime, 'h:mm a');
      } else if (props.isStrictWordsTimeDisplay) {
        this.displayTime = `${distanceInWordsStrict(props.datetime, props.dateTimeToCompare)} ago`;
      } else {
        this.displayTime = `${distanceInWordsToNow(props.datetime)} ago`;
      }
    }
  }

  shouldComponentUpdate() {
    return !this.isMoreThanAnHourOld;
  }

  render() {
    const {datetime, hasClockIcon, isStrictWordsTimeDisplay, type} = this.props;
    if (!datetime) {
      return false;
    }
    return (
      <p
        className={classnames({
          atom: true,
          timestamp: true,
          'timestamp--sm': type === 'small',
          'is-emphasized': isStrictWordsTimeDisplay,
        })}
      >
        {hasClockIcon ? <SVG type="icon" target="clock" /> : null}
        {this.displayTime}
      </p>
    );
  }
}

export default Timestamp;
