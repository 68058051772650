const VIDEO_PLAYER_ACCENT_COLOR = '#ff2a44';

/**
 * generateUIConfigurations builds the full and paused UI configurations
 */
export default function generateUIConfigurations(posterImage, UIControlName, UIControlSet) {
  const full = {
    posterImage,
    theme: {
      accentColor: VIDEO_PLAYER_ACCENT_COLOR,
    },
    sets: {
      controlBar: {
        sets: {
          [UIControlSet.Live]: [
            UIControlName.Play,
            UIControlName.Volume,
            UIControlName.Closed_Captions,
            UIControlName.Fullscreen,
            UIControlName.Progress_Bar,
            UIControlName.Live,
          ],
          [UIControlSet.DVR]: [
            UIControlName.Play,
            UIControlName.Volume,
            UIControlName.Closed_Captions,
            UIControlName.Fullscreen,
            UIControlName.Progress_Bar,
            UIControlName.Live,
          ],
          [UIControlSet.VOD]: [
            UIControlName.Play,
            UIControlName.Volume,
            UIControlName.Closed_Captions,
            UIControlName.Fullscreen,
            UIControlName.Progress_Bar,
          ],
          [UIControlSet.Live_No_DVR]: [
            UIControlName.Play,
            UIControlName.Volume,
            UIControlName.Closed_Captions,
            UIControlName.Fullscreen,
            UIControlName.Progress_Bar,
          ],
        },
      },
    },
  };

  const paused = {
    ...full,
    sets: {
      controlBar: {
        sets: {
          [UIControlSet.Live]: [],
          [UIControlSet.DVR]: [],
          [UIControlSet.VOD]: [],
          [UIControlSet.Live_No_DVR]: [],
        },
      },
    },
  };

  return {
    full,
    paused,
  };
}
