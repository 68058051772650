import {getAdsProfileId, getCsid, getAppCsid} from './liveVideoAdsprofile';

export function getAdsConfig(playerProps) {
  const {ui, isStubPage, oneTrustPreferences = {}} = playerProps;
  const platform = ui.isMobileDevice ? 'mobile' : 'desktop';
  const freeVideoAdsProfile = getAdsProfileId(platform);
  const conf_csid = getCsid(platform);
  const app_csid = getAppCsid(platform, isStubPage);
  const hasOptOutSalePersonalData = !oneTrustPreferences.social;

  return {
    profile: freeVideoAdsProfile,
    kvps: {
      conf_csid,
      ...(isStubPage && {app_csid}),
      _fw_ar: '0',
      _fw_us_privacy: `1Y${hasOptOutSalePersonalData ? 'Y' : 'N'}N`,
    },
  };
}
