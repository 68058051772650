import React from 'react';
import classnames from 'classnames';
import {SVG} from '@br/br-components';

const VideoIndicator = (props) => {
  const classes = classnames('atom', 'video-indicator');
  const {onVideoIndicatorClick} = props;

  function clickHandler() {
    onVideoIndicatorClick();
  }

  return (
    <div className={classes}>
      <a href="/videos" className="video-page-link" onClick={clickHandler}>
        <span className="text">
          ✨ Watch more top videos, highlights, and B/R original content{' '}
        </span>{' '}
        <SVG type="icon" target="rightArrow" />
      </a>
    </div>
  );
};

export default VideoIndicator;
