/* eslint-disable react/jsx-no-bind */
import loadable from '@loadable/component';
import React, {useEffect, useReducer, useRef} from 'react';
import PropTypes from 'prop-types';

import Thumbnail from '../thumbnail';

import {generateConvivaConfig} from './analytics/conviva';
import * as tagManagerEvents from './analytics/tagManagerEvents';

import generateUIConfigurations from './topUIConfigurations';
import logger from '../../../logger';
import Badge from '../../atoms/badge';
import Banner from '../../atoms/banner';
import {COUNTRY_CODES} from '../../../constants/locales';
import TveFooter from './tve/tveFooter';

import {videoStates} from '../../../constants/videoStates';
import * as TveAuth from './tve/tveConfig';
import {getAdsConfig} from './freeVideoConfig';
import TveFreePreviewEndedOverlay from './tve/tveFreePreviewEndedOverlay';
import * as VideoHelpers from '../../helpers/videoHelpers';
import * as VideoEvents from './videoEvents';

import {SVG} from '@br/br-components';

import {retrieveBlackoutInformation} from '../../../apis/vid_api';

const TOP_PLAYER_APP_ID =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuZXR3b3JrIjoiYmxlYWNoZXJyZXBvcnQiLCJwcm9kdWN0IjoiYnJwcm9wZXItdHZlIiwicGxhdGZvcm0iOiJ3ZWItdG9wMiIsImFwcElkIjoiYmxlYWNoZXJyZXBvcnQtYnJwcm9wZXItdHZlLXdlYi10b3AyLWRwY2RidyJ9.6VQIaXLDrzzaqfKj5vxtm1nWrPsgUC9qumWj8TmKXcU';
const ONETRUST_TIMEOUT = 2000;

const BLACKOUT_STATUS = {
  unchecked: 'unchecked',
  enabled: 'enabled',
  disabled: 'disabled',
};

const initialState = {
  adFinished: false,
  adLoaded: false,
  AdTimeoutCheckTriggered: false,
  blackoutStatus: BLACKOUT_STATUS.unchecked,
  currentMediaTime: TveAuth.DEFAULT_TVE_EXPIRATION,
  freePreviewCountdown: TveAuth.DEFAULT_TVE_EXPIRATION,
  freeViewInitialized: false,
  freeViewToken: '',
  hasError: false,
  hasStartedPlayingPreview: false,
  isDVRMode: false,
  isFreePreviewExpired: false,
  isFullScreen: false,
  isMuted: false,
  isPaused: false,
  isPlaying: false,
  playerInitialized: false,
  viewMode: '',
};
const noop = () => {};

let playerConfigBuilder = null,
  uiConfigurations = null,
  contentEntryBuilder = null,
  uiControlSet = null,
  sessionContentBuilder = null;

let freeViewEvents;

/**
 * buildConfig will generate a player config object with the given UI, autoplay, and container.
 * If the `container` parameter is passed it's assumed the config is being built for setup, otherwise it's assumed it's being built for an update
 */
function buildConfig({playerProps, ui, container = null}) {
  const {autoplay, oneTrustPreferences = {}, user = {}} = playerProps;
  const hasExplicitOptOutNotice = !(!user.siteLocale || user.siteLocale === COUNTRY_CODES.USA);
  const hasOptOutSalePersonalData = !oneTrustPreferences.social;
  const builder = container
    ? playerConfigBuilder.forSetup().withContainer(container)
    : playerConfigBuilder.forUpdate();

  return builder
    .withMetadata({
      appId: TOP_PLAYER_APP_ID,
      env: process.env.LIVE_VIDEO_SERVICES_ENV || 'prod', // Configure all services to hit the ite environment
    })
    .withFeatures({
      ccpa: {
        doNotSell: hasOptOutSalePersonalData,
        enabled: hasExplicitOptOutNotice,
      },
    })
    .withUI(ui)
    .withPlayback({
      autoplay,
      muted: autoplay,
    })
    .withDebug({enabled: process.env.NODE_ENV === 'development'})
    .withConviva(oneTrustPreferences.performance ? generateConvivaConfig(playerProps, true) : {})
    .build();
}

function reducer(state, action) {
  switch (action.type) {
    default:
      return {
        ...state,
        ...action.payload,
      };
  }
}

function Video(props) {
  const {
    autoplay = false,
    badge,
    blackout,
    channel = null,
    fetchPPVEntitlementById,
    hlsMediaUrl,
    hookTitle = '',
    isLiveVideo,
    liveVideoOptions = {},
    mediaId,
    onBlackout,
    oneTrustPreferences,
    page = {},
    pageSpecificAttributes = {},
    posterImage,
    league,
    liveEventState,
    setAuthorizationResponse,
    track = {},
    trackEvent = noop,
    videoId = '',
    isStubPage = false,
    user,
    ui,
    saveUserEntitlements,
  } = props;
  const tveOptions = liveVideoOptions.tveOptions || {};
  const sponsoredAd = liveVideoOptions.sponsoredAd || null;
  const {isPPV, isTVE, isTVEFreePreview, setTveAuthContext, setTveProviderLogo} = tveOptions;

  const tveStubURL = isTVE && track && track.metadata && track.metadata.share_url;
  const isValidTveStubTrack = isStubPage && tveStubURL && tveStubURL.includes('post');

  const player = useRef(null);
  const freeView = useRef(null);

  const [state, dispatch] = useReducer(reducer, initialState);
  const vidRefContainer = useRef();
  const {context, authBlock} = ui.tveAuthContext;

  TveAuth.useCustomTVEPicker(authBlock);

  const isAuthInitialized = context && context.authenticatedState;
  const isTVEAuthenticated = context && context.authenticatedState === 'authenticated';

  const useFreePreview = isTVEFreePreview && !isTVEAuthenticated;

  const tveProviderLogo = ui.tveProviderLogo;
  const MAX_BIT_RATE = 3500;
  // PPV Events in 'replay' state use a different companyId
  const BR_COMPANY_ID =
    liveEventState === 'replay'
      ? '2049b15f-7f81-421b-a22e-b317b68a9955'
      : '980e2b29-3c22-4d43-ab80-dc4fdcb599c0';

  function setupAirPlaySafari(player, props) {
    if (!window.WebKitPlaybackTargetAvailabilityEvent) {
      return;
    }

    let playerVideoEl = null;
    const {metadata, type, pageSpecificAttributes} = props;
    const airPlayState = {
      available: false,
      active: false,
    };

    player.events.viewStateChanged.listen((result) => {
      if (result.currentState === 'windowed') {
        const rootEl = player.model.rootContainer || window.TOP.Player.model.rootContainer;
        playerVideoEl = rootEl.querySelector('.top-player-video-element');

        playerVideoEl.addEventListener('webkitplaybacktargetavailabilitychanged', (event) => {
          if (event.availability === 'available') {
            airPlayState.available = true;
          } else {
            airPlayState.available = false;
          }
          player.sendMessageToUI('remoteTargetsChanged', {airplay: airPlayState});
        });

        playerVideoEl.addEventListener('webkitcurrentplaybacktargetiswirelesschanged', () => {
          if (playerVideoEl.webkitCurrentPlaybackTargetIsWireless) {
            airPlayState.active = true;
            // Track Airplay Event
            trackEvent({
              streamName: metadata.analytics?.stream,
              isLive: liveEventState === videoStates.LIVE,
              liveVideoType: type,
              screen: pageSpecificAttributes?.article_type,
              videoTitle: metadata.analytics?.title,
              tag_manager_event: tagManagerEvents.CASTING_SUCCESS,
            });
          } else {
            airPlayState.active = false;
          }

          player.updateConfig(
            buildConfig({
              playerProps: {
                ...props,
                viewMode: airPlayState.active ? 'remote' : player.viewState,
              },
            })
          );
          player.sendMessageToUI('remoteTargetsChanged', {airplay: airPlayState});
        });
      }
    });

    player.events.messageFromUI.listen((result) => {
      switch (result.name) {
        // A user clicked on the airplay button in the Player UI, requesting airplay
        case 'requestAirplay':
          playerVideoEl.webkitShowPlaybackTargetPicker();
          break;

        // The Player UI is requesting the most recent state of your app
        case 'requestRemoteTargetsStatus':
          player.sendMessageToUI('remoteTargetsChanged', {airplay: airPlayState});
          break;

        default:
        // Ignore other Player UI messages
      }
    });
  }

  function playTVEWithToken(token) {
    if (!channel) {
      logger.error('Authorization failed: no channel', channel);
      return;
    }

    if (
      useFreePreview &&
      (player.current.contentState === 'mediaPlayback' ||
        player.current.contentState === 'AdsPlayback')
    ) {
      return;
    }

    const playerConfig = playerConfigBuilder
      .forPlay()
      .withAds(TveAuth.getAdsConfig(props, context, useFreePreview))
      .withMetadata({companyID: TveAuth.getCompanyID(channel)})
      .withMedia({maxBitrate: MAX_BIT_RATE})
      .withUI({activeControlSet: uiControlSet.Live_No_DVR})
      .withPlayback({
        autoplay,
        muted: autoplay,
      })
      .build();
    const playOptions = contentEntryBuilder
      .forEntryOptions()
      .withAccessToken(token)
      .withAccessTokenType(useFreePreview ? 'jws' : 'adobe')
      .withPlayConfigOverrides(playerConfig)
      .build();

    if (useFreePreview || isTVEAuthenticated) {
      player.current.playByMediaJson(
        {
          mediaId,
        },
        playOptions
      );
    }
  }

  function playPPVWithToken(token) {
    if (!playerConfigBuilder || !token) return;
    const playerConfig = playerConfigBuilder
      .forPlay()
      .withMetadata({companyID: BR_COMPANY_ID})
      .withMedia({maxBitrate: MAX_BIT_RATE})
      .withPlayback({
        autoplay,
        muted: autoplay,
      })
      .build();
    const playOptions = contentEntryBuilder
      .forEntryOptions()
      .withAccessToken(token)
      .withAccessTokenType('jws')
      .withPlayConfigOverrides(playerConfig)
      .build();

    player.current.playByMediaJson(
      {
        mediaId,
      },
      playOptions
    );
  }

  function authorizeAndPlay(authBlock) {
    if (!channel) {
      logger.error('Authorization failed: no channel', channel);
      return;
    }

    authBlock
      .authorize({channel, metadata: {rating: 'NR', ratingScheme: 'urn:mpaa'}})
      .then((result) => {
        setAuthorizationResponse({hasError: false, errorMessage: ''});
        playTVEWithToken(result.token);
      })
      .catch((error) => {
        const {code, message} = error;
        setAuthorizationResponse({hasError: true, errorMessage: message, code});
        logger.error('Authorization failed: ', error, code);
      });
  }

  const onTveLoginClicked = () => {
    if (!player.current) return;
    player.current.exitFullscreen();
    TveAuth.handleLogin({
      authBlock,
      setTveAuthContext,
      authorizeAndPlay,
      setTveProviderLogo,
      isValidTveStubTrack,
      tveStubURL,
    });
  };

  const onPreviewExpired = () => {
    player.current.stop();
    const {
      liveVideoMetadata = {},
      metadata = {},
      type,
      pageSpecificAttributes,
      track,
      tagId,
    } = props;
    const videoMetadata = liveVideoMetadata[metadata.live_event_id];

    trackEvent({
      league: videoMetadata?.league?.name,
      streamName: metadata?.analytics?.stream,
      isLive: liveEventState === videoStates.LIVE,
      liveVideoType: type,
      streamID: tagId,
      contentID: track.id,
      screen: pageSpecificAttributes?.article_type,
      videoTitle: videoMetadata?.analytics?.title,
      tag_manager_event: tagManagerEvents.FREE_PREVIEW_EXPIRED,
    });

    VideoHelpers.showFullScreenContent(
      <TveFreePreviewEndedOverlay
        authInitialized={authBlock && context && context.authenticatedState}
        onTveLogin={onTveLoginClicked}
      />
    );
  };

  const tvePlay = async () => {
    const notValidStubTrack = isTVE && !isValidTveStubTrack && tveStubURL;
    if (notValidStubTrack && tveStubURL) {
      window.location.href = tveStubURL.replace(/""/, '');
    }

    if (!state.playerInitialized) return;
    if (useFreePreview) {
      if (!state.freeViewInitialized) return;
      await TveAuth.startFreeViewSession(sessionContentBuilder, freeView, mediaId);
      playTVEWithToken(state.freeViewToken);
      if (state.hasStartedPlayingPreview) return;
      dispatch({payload: {hasStartedPlayingPreview: true}});
    } else if (isTVEAuthenticated) {
      authorizeAndPlay(authBlock);
    } else {
      onTveLoginClicked();
    }
  };

  const freePreviewConfig = {
    show: useFreePreview,
    expired: state.isFreePreviewExpired,
    displayTime: state.freePreviewCountdown,
    label:
      state.hasStartedPlayingPreview ||
      state.isFreePreviewExpired ||
      state.freePreviewCountdown < TveAuth.MAX_FREEVIEW_TIME
        ? 'Remaining'
        : 'Start Preview',
    onClick: tvePlay,
  };

  const completionInterval = () => {
    const duration = player.current.mediaDuration;
    const currentTime = player.current.mediaTime;

    // The % interval of completion of the video - 0, 25, 50, 75, 95, 100 - note this should round down to the nearest interval value (ie. a user that complete 33% of the video will be tracked at 25)
    const completionInterval = Math.round(((currentTime / duration) * 100) / 25) * 25;
    return isNaN(completionInterval) ? 0 : completionInterval;
  };
  const sharedMediaAttributes = () => {
    const {created_at: createdAt, provider_name, title} = track.metadata || {};
    let {analytics = {}} = track.metadata || {};
    analytics = analytics || {};
    const published_at =
      createdAt && createdAt.toISOString && createdAt.toISOString()
        ? createdAt.toISOString()
        : createdAt;

    return {
      ...pageSpecificAttributes,
      autoplay,
      content_id: Number(videoId),
      content_placement: 0,
      content_score: '',
      content_type: track.content_type,
      experiment_type: '',
      hook: hookTitle,
      progress_type: '',
      published_at: published_at || analytics.published_at,
      source: provider_name,
      video_player_experience: '',
      video_title: title || analytics.title,
    };
  };
  const trackContentPlaySummary = () => {
    trackEvent({
      ...sharedMediaAttributes(),
      captions_enabled: player.current.captions.enabled,
      completion_interval: completionInterval(),
      content_duration: player.current.mediaDuration,
      fullscreen_enabled: player.current.model.viewState === 'fullscreen',
      player_time: player.current.mediaTime,
      sound_enabled: !player.current.muted,
      tag_manager_event: tagManagerEvents.CONTENT_PLAY_SUMMARY,
    });
  };

  const play = () => {
    if (mediaId && !isPPV && !isTVE) {
      const playerConfig = playerConfigBuilder
        .forPlay()
        .withAds(getAdsConfig(props))
        .withMedia({maxBitrate: MAX_BIT_RATE})
        .withUI({activeControlSet: uiControlSet.Live_No_DVR})
        .withPlayback({
          autoplay,
          muted: autoplay,
        })
        .build();
      const playOptions = contentEntryBuilder
        .forEntryOptions()
        .withPlayConfigOverrides(playerConfig)
        .build();

      player.current.playByMediaJson(
        {
          mediaId,
        },
        playOptions
      );
      return;
    }

    if (hlsMediaUrl) {
      const contentEntryData = contentEntryBuilder
        .forEntryData()
        .addFile({url: hlsMediaUrl})
        .isLive(liveEventState === videoStates.LIVE)
        .build();

      player.current.play(contentEntryData);
      return;
    }
  };

  const showFullScreenTVEInfo = () => {
    TveAuth.showFullScreenTVEInfo(
      context,
      useFreePreview,
      freePreviewConfig,
      onTveLoginClicked,
      tveProviderLogo
    );
  };

  useEffect(() => {
    if (!blackout || !blackout.enabled) {
      dispatch({payload: {blackoutStatus: BLACKOUT_STATUS.disabled}});
      return;
    }

    retrieveBlackoutInformation(TOP_PLAYER_APP_ID, props.mediaId, blackout.title_id)
      .then((blackoutResponse) => {
        if (blackoutResponse.enabled) {
          onBlackout(blackoutResponse);
        }

        const blackoutStatus = blackoutResponse.enabled
          ? BLACKOUT_STATUS.enabled
          : BLACKOUT_STATUS.disabled;
        dispatch({payload: {blackoutStatus}});
      })
      .catch((error) => {
        logger.error('Blackout call error: ', error);
      });
  }, [blackout, channel, onBlackout, props.mediaId]);

  useEffect(() => {
    if (player.current || !isAuthInitialized || state.blackoutStatus !== BLACKOUT_STATUS.disabled) {
      return () => {};
    }

    function initializePlayer({
      Player,
      PlayerConfigBuilder,
      UIControlName,
      UIControlSet,
      ContentEntryBuilder,
    }) {
      player.current = new Player();
      playerConfigBuilder = PlayerConfigBuilder;
      contentEntryBuilder = ContentEntryBuilder;
      uiControlSet = UIControlSet;
      uiConfigurations = generateUIConfigurations(posterImage, UIControlName, UIControlSet);

      player.current.events.playerReady.once(function() {
        logger.debug('Player ready');
        dispatch({payload: {playerReady: true}});
      });

      const setupConfig = buildConfig({
        playerProps: props,
        ui: uiConfigurations.full,
        container: vidRefContainer.current,
      });
      const {metadata, type, pageSpecificAttributes} = props;
      player.current.setup(setupConfig);
      setupAirPlaySafari(player.current, {
        metadata,
        type,
        pageSpecificAttributes,
        oneTrustPreferences,
      });
      dispatch({payload: {playerInitialized: true}});
    }

    function initializeFreeView({FreeView, FreeViewConfigBuilder, SessionContentBuilder}) {
      sessionContentBuilder = SessionContentBuilder;
      const freeViewConfig = FreeViewConfigBuilder.create()
        .withAppId(TOP_PLAYER_APP_ID)
        .withSecretKey(process.env.FREE_VIEW_SECRET)
        .withFingerprintJS()
        .build();

      freeView.current = new FreeView(freeViewConfig);
      dispatch({payload: {freeViewInitialized: true}});

      if (useFreePreview) {
        TveAuth.startFreeViewSession(sessionContentBuilder, freeView, mediaId, dispatch, state);
      }
    }

    function loadPlayer() {
      logger.debug('Load player');
      const playerBlockWeb = loadable.lib(() => import('@top/player-block-web'), {ssr: false});

      playerBlockWeb.load().then((playerWebDefault) => {
        initializePlayer(playerWebDefault);
      });

      if (useFreePreview) {
        const freeViewBlock = loadable.lib(() => import('@top/freeview-block-web'), {ssr: false});
        freeViewBlock.load().then((freeViewObject) => {
          initializeFreeView(freeViewObject);
        });
      }
    }

    // Wait for oneTrust preferences and user entitlements
    if (oneTrustPreferences && (!isPPV || (isPPV && user.entitlements))) {
      loadPlayer();
      return () => {};
    }

    const timeout = setTimeout(() => {
      logger.debug('One trust timed out');
      loadPlayer();
    }, ONETRUST_TIMEOUT);
    return () => clearTimeout(timeout);

    // only run initalize logic if one of the following values change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoplay, hlsMediaUrl, oneTrustPreferences, isAuthInitialized, state.blackoutStatus]);

  useEffect(() => {
    async function getPPVAuth() {
      try {
        const mediaEntitlements = await fetchPPVEntitlementById(mediaId);
        const entitlement = mediaEntitlements.payload[0];
        const playToken = entitlement?.token;
        saveUserEntitlements(mediaEntitlements.payload);
        dispatch({payload: {ppvPlayToken: playToken}});
      } catch {
        // If an error happens here, a click on the play icon in the player will trigger orbisAuthentication above and force this to be recalled
        logger.debug('Error fetching entitlements');
      }
    }
    if (isPPV && isStubPage) {
      getPPVAuth();
    }
  }, [mediaId, saveUserEntitlements, liveEventState, isPPV, isStubPage, fetchPPVEntitlementById]);

  useEffect(() => {
    /**
     * @see https://httpstream.ngtv.io/internal/top-2.0/releases/2_0_0-rc.1/player/docs/web/modules/player_api_events.html#playereventmapfactory
     * For full event list
     */

    if (!player || !player.current || !state.playerInitialized) {
      return noop;
    }

    if (useFreePreview && (!freeView || !freeView.current || !state.freeViewInitialized))
      return noop;

    const listenBinding = player.current.events.listen(
      VideoEvents.getAllVideoEvents({
        buildConfig,
        completionInterval,
        dispatch,
        freeView,
        isValidTveStubTrack,
        page,
        play,
        player,
        props,
        sharedMediaAttributes,
        showFullScreenTVEInfo,
        state,
        trackContentPlaySummary,
        trackEvent,
        tveStubURL,
        uiConfigurations,
        useFreePreview,
      })
    );

    if (useFreePreview) {
      freeViewEvents = freeView.current.events.listen(
        VideoEvents.freePreviewEvents({
          dispatch,
          player,
          onPreviewExpired,
        })
      );
    }

    return () => {
      if (useFreePreview && freeView.current) {
        freeView.current.events.unlisten(freeViewEvents);
      }
      player.current && player.current.events.unlisten(listenBinding);
    };
  });

  useEffect(() => {
    // Trigger the adFinished flag after timeout if there are no ads currently playing
    if (!isPPV && !isTVE && state.AdTimeoutCheckTriggered && !state.adLoaded) {
      dispatch({
        payload: {
          adFinished: true,
        },
      });
    }
  }, [isPPV, isTVE, state.AdTimeoutCheckTriggered, state.adLoaded]);

  const isUnauthorized = !authBlock || !context || context.authenticatedState !== 'authenticated';

  useEffect(() => {
    const {playerInitialized, ppvPlayToken, playerReady} = state;
    if (!playerInitialized) return;

    if (isPPV && playerReady && ppvPlayToken) {
      playPPVWithToken(ppvPlayToken);
    }

    if (!isPPV && !isTVE && playerReady) {
      // This is a necessary change to deal with edge case of free live media not playing
      // We want to also make sure to not affect existing TVE and PPV functionality
      play();
    }

    if (isTVE && isTVEAuthenticated) {
      if (state.hasStartedPlayingPreview) dispatch({payload: {hasStartedPlayingPreview: false}});
      authorizeAndPlay(authBlock);
    }

    if (isTVE && isUnauthorized && player && state.isPlaying) {
      if (isTVEFreePreview && !state.isFreePreviewExpired) {
        player.current.updateConfig(
          buildConfig({
            playerProps: props,
            ui: {enabled: false},
          })
        );
      }
      player.current.stop();
      if (isTVEFreePreview && !state.isFreePreviewExpired) {
        tvePlay();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoplay, isUnauthorized, state.playerInitialized, state.playerReady, state.ppvPlayToken]);

  useEffect(() => {
    const destroyPlayer = () => {
      if (player) {
        if (isLiveVideo) trackContentPlaySummary();
        player.current.destroy();
        vidRefContainer.current = null;
        player.current = null;
      }

      if (freeView?.current) {
        freeView.current.destroy();
        freeView.current = null;
      }
    };

    global.addEventListener('beforeunload', destroyPlayer);

    if (!state.isDVRMode && liveEventState === videoStates.ENDED) {
      destroyPlayer();
    }

    return () => {
      global.removeEventListener('beforeunload', destroyPlayer);

      if (isLiveVideo && liveEventState !== videoStates.ENDED) return;
      destroyPlayer();
    };

    // only run when live event status changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveEventState, state.isDVRMode]);

  const authInitialized = authBlock && context && context.authenticatedState;

  const showVideoPlay =
    isTVE && useFreePreview
      ? authInitialized && state.playerInitialized && state.freeViewToken
      : authInitialized && state.playerInitialized;

  const videoPlayerThumbnail = (
    <div>
      <Thumbnail badge={badge} isVideo={true} onClick={tvePlay} thumbnail={posterImage}>
        {showVideoPlay && <SVG type="icon" target="videoPlay" />}
      </Thumbnail>
    </div>
  );

  const showTVE = process.env.TVE_ENABLED && isTVE;
  const showThumbnail =
    showTVE && isUnauthorized && (!useFreePreview || !state.hasStartedPlayingPreview);
  const showPreviewEndedThumbnail = showTVE && useFreePreview && state.isFreePreviewExpired;
  const isNCAAGame = league?.acronym === 'NCAAMB';

  return (
    <>
      {showPreviewEndedThumbnail
        ? TveAuth.freePreviewExpiredThumbnail(
            authInitialized,
            onTveLoginClicked,
            posterImage,
            isNCAAGame
          )
        : showThumbnail && videoPlayerThumbnail}
      <div hidden={showThumbnail || showPreviewEndedThumbnail} className="molecule nr-video-player">
        {!state.isPlaying && badge && badge.text ? <Badge {...badge} /> : null}
        {state.isPlaying && state.adFinished && !state.isFullScreen && sponsoredAd && (
          <Banner {...sponsoredAd} isFullScreen={false} />
        )}

        <div className="nr-video-player__container content" ref={vidRefContainer} />
      </div>
      {showTVE && authInitialized && (
        <TveFooter
          handleLogin={onTveLoginClicked}
          isAuthenticated={isTVEAuthenticated}
          imageURL={tveProviderLogo}
          context={context}
          freePreviewConfig={freePreviewConfig}
          useFreePreview={useFreePreview}
        />
      )}
    </>
  );
}

Video.propTypes = {
  autoplay: PropTypes.bool,
  blackout: PropTypes.shape({}),
  hlsMediaUrl: PropTypes.string,
  hookTitle: PropTypes.string,
  isLiveVideo: PropTypes.bool,
  liveEventState: PropTypes.string,
  liveVideoOptions: PropTypes.shape({}),
  mediaId: PropTypes.string,
  metadata: PropTypes.shape({}),
  onAdEnd: PropTypes.func,
  onAdError: PropTypes.func,
  onAdStart: PropTypes.func,
  onBlackout: PropTypes.func,
  onContentEnded: PropTypes.func,
  onError: PropTypes.func,
  onPause: PropTypes.func,
  onPlayerStateChange: PropTypes.func,
  onResume: PropTypes.func,
  onStart: PropTypes.func,
  onStop: PropTypes.func,
  oneTrustPreferences: PropTypes.shape({}),
  pageSpecificAttributes: PropTypes.shape({}),
  posterImage: PropTypes.string,
  saveUserEntitlements: PropTypes.func,
  track: PropTypes.shape({}),
  trackEvent: PropTypes.func,
  ui: PropTypes.shape({}),
  user: PropTypes.shape({}),
  videoId: PropTypes.string,
};

export default Video;
