import React from 'react';
import PropTypes from 'prop-types';
import SignInButton from './signInButton';
import ProviderInfo from './providerInfo';
import FreePreviewTimer from './freePreviewTimer';

function TveFooter(props) {
  const {useFreePreview, context, imageURL} = props;
  const expired = props.freePreviewConfig && props.freePreviewConfig.expired;

  const showForFreePreview = useFreePreview && !expired;
  const showForRegular = !useFreePreview && !props.isAuthenticated;
  const showButton = showForFreePreview || showForRegular;

  return (
    <>
      <div className="molecule tve-footer">
        <div className="tve-footer__left">
          {props.freePreviewConfig && props.freePreviewConfig.show && (
            <FreePreviewTimer config={props.freePreviewConfig} />
          )}
        </div>
        <div className="tve-footer__right">
          {showButton ? (
            <SignInButton handleLogin={props.handleLogin} />
          ) : (
            <ProviderInfo context={context} imageURL={imageURL} />
          )}
        </div>
      </div>
    </>
  );
}

TveFooter.propTypes = {
  context: PropTypes.shape(PropTypes.object),
  handleLogin: PropTypes.func,
  imageURL: PropTypes.string,
  isAuthenticated: PropTypes.bool,
};

export default TveFooter;
