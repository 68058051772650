import React from 'react';
import classnames from 'classnames';

import Commentary from '../atoms/commentary';
import Timestamp from '../atoms/timestamp';
import LazyImage from '../atoms/lazyImage';
import ArticleLeadImage from '../atoms/articleLeadImage';
import {getArticleThumb} from '../../helpers/imageHelpers';

class Photo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleImageClick = this.handleImageClick.bind(this);
  }

  handleImageClick() {
    this.props.handleImageClick(this.props.id);
  }

  render() {
    const {src, size} = this.props;
    const isHttps = src.startsWith('https://');
    const classes = classnames({
      atom: true,
      error: !isHttps,
      photo: true,
      gif: this.props.type === 'gif',
      selected: this.props.selected,
    });
    const timestamp = this.props.hasTimestamp ? this.props.timestamp : null;
    const additionalImgProps = {itemProp: 'contentUrl', width: size.w, height: size.h};
    let duration = this.props.duration ? this.props.duration : null;
    if (duration) {
      const min = Math.floor(duration / 60);
      const sec = duration - min * 60;
      duration = `${min}:${sec > 9 ? sec : `0${sec}`}`;
    }

    return isHttps ? (
      <this.props.tag
        className={classes}
        onClick={this.handleImageClick}
        itemScope={true}
        itemType="http://schema.org/ImageObject"
      >
        <Timestamp datetime={timestamp} />
        <Commentary {...this.props.commentary} />
        <p>{this.props.title ? this.props.title : null}</p>
        {this.props.showDuration && (
          <>
            <div className="grad-bg">
              <span>{duration}</span>
            </div>
          </>
        )}
        <div className="imgWrapper">
          {this.props.isLeadImage && this.props.blurArticleLeadImage ? (
            <ArticleLeadImage
              {...additionalImgProps}
              alt={
                this.props.userCaption ||
                this.props.caption ||
                this.props.altText ||
                this.props.alt ||
                this.props.title
              }
              src={getArticleThumb(src, size)}
              blurUpImgSrc={getArticleThumb(src, {w: 40, h: 27})} // For the blur, we basically use a tiny version of the same image
            />
          ) : (
            <LazyImage
              {...additionalImgProps}
              alt={
                this.props.userCaption ||
                this.props.caption ||
                this.props.altText ||
                this.props.alt ||
                this.props.title
              }
              src={getArticleThumb(src, size)}
            />
          )}
        </div>
        <small>
          <span className="caption">{this.props.userCaption}</span>
          <span itemProp="citation" className="credit">
            {this.props.credit}
          </span>
        </small>
      </this.props.tag>
    ) : (
      <div className={classes}>
        <a href={src} target="_blank" rel="noopener noreferrer">
          Link to Media
        </a>
      </div>
    );
  }
}

/* defaults */
Photo.defaultProps = {
  provider: '',
  caption: '',
  size: {w: 970, h: 500},
  src: '',
  tag: 'div',
  handleImageClick: () => {}, // stub
  isLeadImage: false,
};

export default Photo;
