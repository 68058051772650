import React, {useEffect, useRef, useState, useCallback} from 'react';
import classnames from 'classnames';
import {connect} from 'react-redux';
import {ui} from '../../selectors/topLevelSelectors';
import {imgSizes, useImgSrcSet} from '../../helpers/imageHelpers';
import {withCloudinary} from '../../helpers/cloudinaryHelper';
import logger from '../../logger';

const loadImage = ({src, srcSet, sizes, elem}) =>
  new Promise((resolve, reject) => {
    elem.onload = () => resolve(elem);
    elem.onerror = reject;
    if (srcSet) {
      elem.srcset = srcSet;
      elem.sizes = sizes;
    }
    elem.src = src;
  });

const ArticleLeadImage = (props) => {
  const {alt, assetURL, moduleType, src, blurUpImgSrc, itemProp, width, height} = props;
  const [updatedSrc, srcSet] = useImgSrcSet({src, width, height, moduleType});
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const imgRef = useRef(null);

  const loadImageIfNotLoaded = useCallback(async () => {
    if (!isImgLoaded) {
      return await loadImage({
        src: updatedSrc,
        srcSet,
        sizes: imgSizes,
        elem: imgRef.current,
      })
        .then(() => {
          setIsImgLoaded(true);
        })
        .catch((reason) => {
          logger.debug(`Error loading article lead image ${reason}`);
        });
    }
    return Promise.resolve();
  }, [isImgLoaded, srcSet, updatedSrc]);

  const userInteractionEvents = ['keydown', 'mouseover', 'touchmove', 'touchstart', 'wheel'];
  const userInteractionListener = useCallback(async () => {
    await loadImageIfNotLoaded();
    userInteractionEvents.forEach(function(event) {
      window.removeEventListener(event, userInteractionListener, false);
    });
  }, [loadImageIfNotLoaded, userInteractionEvents]);

  useEffect(() => {
    userInteractionEvents.forEach(function(event) {
      window.addEventListener(event, userInteractionListener, false);
    });
    return () => {
      // remove event listeners on cleanup
      userInteractionEvents.forEach(function(event) {
        window.removeEventListener(event, userInteractionListener, false);
      });
    };
  }, [userInteractionEvents, userInteractionListener]);

  if (!src) {
    return null;
  }

  const classes = classnames({
    atom: true,
    articleLeadImage: true,
    blurUp: true,
    loaded: isImgLoaded,
  });

  return (
    <div className={classes}>
      <img
        alt={alt}
        ref={imgRef}
        src={blurUpImgSrc ? blurUpImgSrc : `${assetURL}/img/atoms/lazyImage/logo.png`}
        itemProp={itemProp}
      />
    </div>
  );
};

ArticleLeadImage.defaultProps = {
  alt: '',
};

const mapStateToProps = (state) => {
  const {assetURL} = ui(state);
  return {
    assetURL,
  };
};

export default connect(mapStateToProps)(withCloudinary(ArticleLeadImage));
