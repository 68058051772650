import React, {useEffect, useState} from 'react';
import {
  fetchLiveVideoMetadata,
  retrieveVideoUrl,
  retrieveEntitlementToken,
  retrieveNGTVtoken,
} from '../../../apis/vid_api';
import {contentTypes} from '../../../constants/contentTypes';
import {SECOND} from '../../../constants/time';
import {videoErrorCodes} from '../../../constants/videoErrorCodes';
import {videoStates} from '../../../constants/videoStates';
import {videoTypes} from '../../../constants/videoTypes';
import {STUB} from '../../../constants/pageTypes';
import logger from '../../../logger';

const AIRCHAIN_POLLING_INTERVAL = SECOND * 60;

const BLACKOUT_TYPES = {
  regional: 'regional',
  network: 'network',
  error: 'error',
};

/**
 * @param {string} liveVideoState
 */
const getBadgeProps = (liveVideoState) => {
  if (!liveVideoState || liveVideoState === videoStates.ENDED) return null;

  if (liveVideoState === videoStates.UPCOMING) {
    return {
      text: liveVideoState,
      type: 'primary',
    };
  } else if (liveVideoState === videoStates.LIVE) {
    return {
      text: liveVideoState,
      type: 'secondary',
    };
  }
  return null;
};

const overlayConfigs = {
  authError: {
    text:
      'This channel isn’t available in your package. \nPlease contact your cable provider for more information',
    secondaryText: '',
    allowReload: false,
  },
  mobileError: {
    text:
      'This video is not compatible with your browser. \n Please download the Bleacher Report app using the "Get App" button above or try streaming in Safari in order to access this event.',
    secondaryText: '',
    allowReload: false,
  },
  error: {
    text: 'Something went wrong.',
    secondaryText: 'Please reload your player.',
    allowReload: true,
  },
  ended: {
    text: 'This event is over',
    secondaryText: '',
    allowReload: false,
  },
  endedPPV: {
    text: 'This event is now over.\nReplay will be available soon.',
    secondaryText: '',
    allowReload: false,
  },
  blackoutLocation: {
    text: 'This event is not available in your location.',
    secondaryText: '',
    allowReload: false,
  },
  blackoutNetwork: {
    text: 'This channel isn’t available in your package.',
    secondaryText: 'Please contact your cable provider for more information',
    allowReload: false,
  },
  blackoutError: {
    text: 'There was an error playing this video.',
    secondaryText: '',
    allowReload: false,
  },
};

const BLACKOUT_OVERLAY_MAP = {
  [BLACKOUT_TYPES.regional]: overlayConfigs.blackoutLocation,
  [BLACKOUT_TYPES.network]: overlayConfigs.blackoutNetwork,
  [BLACKOUT_TYPES.error]: overlayConfigs.blackoutError,
};

/**
 * @param {string} liveVideoState
 * @param {function} onReloadClicked
 * @param {boolean} hasError
 */
const getOverlay = (
  liveVideoState,
  onReloadClicked,
  hasError,
  blackoutResponse,
  authorizationResponse,
  isIos,
  isPPV,
  errorCode
) => {
  let currentConfig;
  const {errorMessage, code} = authorizationResponse;
  const isDrmError = errorCode === videoErrorCodes.DRM || errorCode === videoErrorCodes.IOS_DRM;
  if (liveVideoState === videoStates.ENDED) {
    currentConfig = isPPV ? overlayConfigs.endedPPV : overlayConfigs.ended;
  } else if (hasError && isPPV && isIos && isDrmError) {
    // TOP 2.0 Doesn't support DRM for iOS in some cases (GOONIES-1873)
    // We want a less generic error in this case directing the user to download the native app
    currentConfig = overlayConfigs.mobileError;
  } else if (hasError) {
    currentConfig = overlayConfigs.error;
  } else if (authorizationResponse.hasError) {
    currentConfig =
      errorMessage && code === videoErrorCodes.AUTH
        ? overlayConfigs.authError
        : overlayConfigs.blackoutError;
  }

  if (blackoutResponse) currentConfig = BLACKOUT_OVERLAY_MAP[blackoutResponse.type];

  return currentConfig
    ? {
        text: currentConfig.text,
        secondaryText: currentConfig.secondaryText,
        onReloadClicked: currentConfig.allowReload ? onReloadClicked : null,
      }
    : null;
};

const getVideoHlsUrl = ({liveURLs = {}, vodURLs = {}}) => {
  if (liveURLs && liveURLs.hls && liveURLs.hls.primary) {
    return liveURLs.hls.primary;
  } else if (vodURLs && vodURLs.hls && vodURLs.hls.primary) {
    return vodURLs.hls.primary;
  }
  return null;
};

const getCreatorsVideoMetadata = (creators) => {
  let state = creators.state.toLowerCase();
  if (state === 'vod') {
    state = 'replay';
  }

  if (state === 'scheduled') {
    state = 'upcoming';
  }
  return {
    ...creators,
    state,
  };
};

export const useLiveVideo = ({
  track,
  thumbData,
  orbisSession,
  isLoggedInUser,
  liveVideoMetadata,
  page,
  fetchPPVEntitlementById,
  saveUserEntitlements,
  ui,
}) => {
  const {content = {}, content_type} = track;
  const {metadata = {}} = content;
  const {live_event_id} = metadata;

  const isCreators = content_type === contentTypes.CREATORS && !!metadata.creators;
  const defaultMetadata = isCreators
    ? getCreatorsVideoMetadata(metadata.creators)
    : liveVideoMetadata[live_event_id] || {};

  const [hasError, setHasError] = useState(false);
  const [errorCode, setErrorCode] = useState('');
  const [authorizationResponse, setAuthorizationResponse] = useState({
    hasError: false,
    errorMessage: '',
    code: '',
  });
  const [isDVRMode, setDVRMode] = useState(false);

  const [liveVideoMetaData, setLiveVideoMetaData] = useState(defaultMetadata);
  const {state, media_id, type} = liveVideoMetaData;
  const resetLiveVideoMetaData = () => setLiveVideoMetaData(defaultMetadata);

  const [liveVideoUrl, setLiveVideoUrl] = useState('');
  const [blackoutResponse, setBlackoutResponse] = useState(null);

  const isLiveVideo = content_type === contentTypes.LIVE_VIDEO || isCreators;
  const isStubPage = page.type === STUB;
  const isPPV = type === videoTypes.PPV;
  const isIos = ui.os === 'ios';

  useEffect(() => {
    if (hasError) return;
    async function updateVideoMetadata() {
      const data = await fetchLiveVideoMetadata(live_event_id);

      setLiveVideoMetaData(data);
      return data;
    }

    async function pollAirchainLiveVideo() {
      // Step 2.5: Get video data from BR LIve (Layser Endpoint)
      // GOONIES-1810: We always call here even if already made on the BE
      // to prevent stale data from Fastly
      const data = await updateVideoMetadata();

      const {state} = data;
      const shouldStartPolling =
        (type === videoTypes.PPV && state !== videoStates.REPLAY) ||
        (type !== videoTypes.PPV && state !== videoStates.ENDED);

      if (shouldStartPolling) {
        const liveVideoPollingInterval = setInterval(async () => {
          const {state: updatedState} = await updateVideoMetadata();
          const shouldStopPolling =
            (type === videoTypes.PPV && updatedState === videoStates.REPLAY) ||
            (type !== videoTypes.PPV && updatedState === videoStates.ENDED);
          if (shouldStopPolling) {
            clearInterval(liveVideoPollingInterval);
          }
        }, AIRCHAIN_POLLING_INTERVAL);
      }
    }

    async function initLiveVideo() {
      if (isLiveVideo && !isCreators) {
        try {
          await pollAirchainLiveVideo();
        } catch {
          resetLiveVideoMetaData();
          setHasError(true);
        }
      }
    }
    initLiveVideo();
    // Hook deps are only needed if we want the hook to re-execute,
    // here we only want to initialize the polling
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError]);

  useEffect(() => {
    if (hasError || process.env.TOP_PLAYER_ENABLED || isPPV) return;
    async function getLiveVideoUrl() {
      try {
        if (orbisSession.userID && state === videoStates.LIVE && liveVideoUrl === '' && media_id) {
          const {sessionToken, userID} = orbisSession;
          const assetID = media_id;

          // Step 3: Request information about the video with the Orbis API
          const {assets} = await retrieveVideoUrl(assetID, sessionToken);

          if (assets && assets.length > 0) {
            const [asset] = assets;
            const videohlsUrl = getVideoHlsUrl(asset);

            if (!videohlsUrl) {
              // this will be caught and allow the user to retry;
              throw new Error('Unable to get HLS video URL');
            }

            // Step 4: Get an `entitlementToken` from the Orbis API
            const {entitlementToken} = await retrieveEntitlementToken({
              userID,
              sessionToken,
              assetID,
              playbackUrl: videohlsUrl,
            });

            const videoPath = new URL(videohlsUrl).pathname;

            // Step 5
            const {auth} = await retrieveNGTVtoken(entitlementToken, videoPath);

            setHasError(false);
            setLiveVideoUrl(`${videohlsUrl}?hdnts=${auth.token}`);
          } else {
            throw new Error('Unable to get HLS video URL');
          }
        }
      } catch {
        // If an error happens here, a click on the play icon in the player will trigger orbisAuthentication above and force this to be recalled
        setHasError(true);
      }
    }

    // A live video url should only be fetched if it meets the critera below.
    getLiveVideoUrl();
  }, [hasError, isPPV, liveVideoUrl, media_id, orbisSession, state, type]);

  useEffect(() => {
    async function getPPVAuth() {
      try {
        const mediaEntitlements = await fetchPPVEntitlementById(media_id);
        saveUserEntitlements(mediaEntitlements);
      } catch {
        setHasError(true);
      }
    }
    if (isPPV && isStubPage) {
      getPPVAuth();
    }
    // User Entitlements used for PPV auth loading live videos are fetched just once on page load before the video component is loaded.
    // This data is used to determine if we should show a thumbnail or load the TOP player (logic in the videoManager component).
    // After the TOP player has loaded, the video component takes over this responsability, then polling every 60s subsequently.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onVideoError(err) {
    logger.debug('onVideoError', err);
    setErrorCode(err.error?.code || '');
    if (isLiveVideo) {
      // check if error is warn or other severity
      const isWarning = err && err.error && err.error.severity === 'warn';
      if (!isWarning) {
        setHasError(true);
      }
    }
  }

  function reload() {
    if (hasError) {
      resetLiveVideoMetaData();
      setLiveVideoUrl('');
      setHasError(false);
    }
  }

  if (isLiveVideo) {
    const {state: videoState, thumbnail_url: defaultLiveThumbnail} = liveVideoMetaData;
    let hasVideoPlayIcon = false;

    if (videoState === videoStates.LIVE) {
      hasVideoPlayIcon =
        !isLoggedInUser ||
        track.componentType === 'hero' ||
        metadata.live_video_type === videoTypes.FREE;
    }

    if (isCreators && (videoState === videoStates.REPLAY || videoState === videoStates.LIVE)) {
      hasVideoPlayIcon = true;
    }

    const isUrlRequired = !process.env.TOP_PLAYER_ENABLED ? Boolean(liveVideoUrl) : true;
    const isTVENonStub = metadata.live_video_type === videoTypes.TVE && page.type !== STUB;
    const isLiveVideoPlayable =
      !hasError &&
      (videoState === videoStates.LIVE || videoState === videoStates.REPLAY || isDVRMode) &&
      isUrlRequired &&
      !authorizationResponse.hasError &&
      (!blackoutResponse || !blackoutResponse.enabled);
    return {
      ...liveVideoMetaData,
      badge: getBadgeProps(isDVRMode && !isCreators ? videoStates.LIVE : videoState),
      hasVideoPlayIcon,
      isLiveVideo,
      isLiveVideoPlayable,
      liveVideoUrl,
      onBlackout: setBlackoutResponse,
      onMediaTimeChanged: (isDVRMode) => {
        setDVRMode(!isPPV && isDVRMode);
      },
      onVideoEnded: () => {
        setLiveVideoMetaData({
          ...liveVideoMetaData,
          state: videoStates.ENDED,
        });
      },
      onVideoError,
      overlay: getOverlay(
        videoState,
        reload,
        hasError,
        blackoutResponse,
        authorizationResponse,
        isIos,
        isPPV,
        errorCode
      ),
      setAuthorizationResponse,
      thumbData: {
        ...thumbData,
        thumbnail: thumbData.thumbnail || thumbData.thumbnail_url || defaultLiveThumbnail,
      },
      thumbnailClasses: 'cursor-default',
      thumbnailHref: isTVENonStub ? track.url : '',
    };
  }

  return {};
};

const withLiveVideo = (VideoComponent) => ({...props}) => {
  const {
    article: track = {},
    fetchPPVEntitlementById,
    orbisSession = {},
    isLoggedInUser,
    liveVideoMetadata = {},
    thumbData = {},
    page = {},
    user = {},
    saveUserEntitlements,
    ui,
  } = props;
  const liveVideoProps = useLiveVideo({
    fetchPPVEntitlementById,
    isLoggedInUser,
    liveVideoMetadata,
    orbisSession,
    page,
    saveUserEntitlements,
    thumbData,
    track,
    ui,
    user,
  });

  const videoProps = {
    ...props,
    ...liveVideoProps,
    article: track,
  };

  return <VideoComponent {...videoProps} />;
};

export default withLiveVideo;
