import React from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';
import {SVG} from '@br/br-components';

import Commentary from '../../atoms/commentary';
import Photo from '../../atoms/photo';
import Thumbnail from '../thumbnail';
import {getScreenType} from '../../../constants/mParticle';
import TveLogo from '../../atoms/tveLogo';
import VideoCarousel from '../videoCarousel';
import VideoIndicator from '../../atoms/videoIndicator';
import VideoPlayer from './videoPlayer';
import Video from './video';
import VideoPlaylist from './videoPlaylist';
import withLiveVideo from './withLiveVideo';
import {videoTypes} from '../../../constants/videoTypes';
import {videoStates} from '../../../constants/videoStates';
import {contentTypes} from '../../../constants/contentTypes';

import * as AnalyticsActions from '../../../actions/analyticsActions';
import * as AppActions from '../../../actions/appActions';
import * as UserActions from '../../../actions/userActions';
import {ARTICLE, SECTION, STUB, HOME} from '../../../constants/pageTypes';
import {getFormattedLiveEventDate} from '../../helpers/videoHelpers';
import * as TopSelectors from '../../../selectors/topLevelSelectors';
import {isLoggedInUser} from '../../../selectors/userSelectors';
import * as TveAuth from './tve/tveConfig';
import TveFooter from './tve/tveFooter';
import {WarnerMedia, BR, Static} from '../../../endpoints';

export class VideoManager extends React.PureComponent {
  static defaultProps = {
    addStyle: () => {},
    analytics: {},
    article: {},
    author: {},
    autoplay: false,
    fetchPPVEntitlementById: () => {},
    handleContentBox: () => {},
    isVideoInViewport: false,
    liveVideoMetadata: {},
    muted: false,
    onMediaTimeChanged: () => {},
    onVideoEnded: () => {},
    page: {},
    playVideoInline: true,
    saveUserEntitlements: () => {},
    scrollPlay: false,
    showThumb: false,
    tag: 'div',
    thumbData: {},
    tv_channel: null,
    ui: {},
    url: '',
    user: {},
    videoMetadata: {},
    videoPlaylist: false,
    videoRecommendations: [],
  };

  constructor(props) {
    super(props);
    this.playSpecificVideo = this.playSpecificVideo.bind(this);
    this.playNextVideo = this.playNextVideo.bind(this);
    this.onThumbClick = this.onThumbClick.bind(this);
    this.onVideoEnded = this.onVideoEnded.bind(this);
    this.onPlayCarouselVideo = this.onPlayCarouselVideo.bind(this);
    this.updateVideoPlayerReference = this.updateVideoPlayerReference.bind(this);
    this.setRecommendedVideosMode = this.setRecommendedVideosMode.bind(this);
    this.resetSelectedVideo = this.resetSelectedVideo.bind(this);
    this.setVideoLastFrame = this.setVideoLastFrame.bind(this);
    this.trackPlaylistSummary = this.trackPlaylistSummary.bind(this);
    this.handlePurchaseEvent = this.handlePurchaseEvent.bind(this);
    this.isPlayButtonInViewport = this.isPlayButtonInViewport.bind(this);

    // hide_from_regions is actually an array of country codes, not region codes
    this.isGeoBlocked =
      this.props.article.hide_from_regions &&
      this.props.article.hide_from_regions.includes(this.props.user.visitorCountry);
    this.isStubPage = props.page.type === STUB;
    this.isHomePage = props.page.type === HOME;

    // Load required BM styles for pages that contain a video
    this.props.addStyle({
      href: 'https://cdn.bitmovin.com/player/web/8.81.0/bitmovinplayer-ui.css',
      key: 'bitmovinUI',
    });

    this.state = {
      contentPlacement: 0,
      isVideoInViewport: false,
      progressType: {},
      remainingRecommendedVideos: [],
      selectedVideoData: null,
      showVideoCarousel: false,
      thumbClicked: false,
      videoLastFrame: '',
      viewedVideos: [],
      watchedVideos: [],
    };
  }

  async componentDidMount() {
    this.playlistId = `playlist-${this.props.metadata.video_id}`;

    if (!this.props.isLiveVideo && this.props.scrollPlay && this.props.playVideoInline) {
      const options = {
        rootMargin: '0px',
        threshold: 0.5, // half of player height
      };
      this.observer = new IntersectionObserver(this.isPlayButtonInViewport, options);
      const target = this._video;
      this.observer.observe(target);
    }
    const hasRecommendations =
      this.props.videoRecommendations && this.props.videoRecommendations.length;

    if (hasRecommendations) {
      this.setVideoCarouselData();
      global.addEventListener('beforeunload', this.trackPlaylistSummary, false);
    }
  }

  componentWillUnmount() {
    const hasRecommendations =
      this.props.videoRecommendations && this.props.videoRecommendations.length;
    if (hasRecommendations) {
      global.removeEventListener('beforeunload', this.trackPlaylistSummary, false);
    }

    this.updateVideoPlayerReference(null);
    if (this.observer) {
      this.observer.unobserve('svg.videoPlay');
    }
  }

  setRemainingRecommendedVideos(videoId) {
    const hasMoreRecommendations =
      this.state.remainingRecommendedVideos && this.state.remainingRecommendedVideos.length;

    if (hasMoreRecommendations) {
      const remainingRecommendedVideos = this.state.remainingRecommendedVideos.filter((video) => {
        return video.video_id !== videoId;
      });
      this.setState({remainingRecommendedVideos});
    }
  }

  get hlsMediaUrl() {
    const {selectedVideoData} = this.state;
    const {isLiveVideo, liveVideoUrl, videoMetadata, metadata} = this.props;
    if (metadata?.creators?.video_url) {
      return metadata.creators?.video_url;
    }
    const videoId = selectedVideoData ? selectedVideoData.id : metadata.video_id;
    const media = (videoMetadata && videoMetadata[videoId]) || metadata || {};
    const {source, hls_url, hls} = media;

    if (isLiveVideo && liveVideoUrl) {
      return liveVideoUrl;
    }
    return hls_url || hls || (source && source[0].src) || '';
  }

  get shouldShowVideoPlayer() {
    const {thumbClicked} = this.state;
    const {autoplay, isLiveVideo, isLiveVideoPlayable, showThumb, user, type} = this.props;
    const thumbHasBeenClicked = showThumb && thumbClicked;
    const isVideoPlayable = autoplay || !showThumb || thumbHasBeenClicked;
    const isPPV = type === videoTypes.PPV;
    const isUserEntitled =
      user && user.entitlements && user.entitlements.length && user.entitlements[0].accessAllowed;

    // Show Thumbnail if not already purchased or logged in
    if (isPPV && !isUserEntitled) return false;

    if (isLiveVideo) {
      return isLiveVideoPlayable && isVideoPlayable;
    }
    return isVideoPlayable;
  }

  setVideoCarouselData() {
    const {videoRecommendations} = this.props;
    const videoId = this.props.metadata.video_id;
    const title = this.props.videoMetadata[videoId].title;
    this.setState(
      {
        selectedVideoData: {id: videoId, thumbnail: this.props.thumbData.thumbnail, title},
        remainingRecommendedVideos: videoRecommendations,
      },
      () => {
        if (!this.props.isLiveVideo && this.props.scrollPlay) {
          this.setRemainingRecommendedVideos(videoId);
        }
      }
    );
  }

  resetSelectedVideo() {
    this.setState({selectedVideoData: null});
  }

  updateVideoPlayerReference(player) {
    this.player = player;
  }

  isPlayButtonInViewport(entries) {
    entries.forEach((entry) => {
      this.setState({
        isVideoInViewport: entry.isIntersecting,
      });

      if (entry.isIntersecting && this.props.showThumb && !this.state.thumbClicked) {
        if (this.props.showThumb) {
          this.setState({
            thumbClicked: true,
          });
        }
      }

      if (entry.isIntersecting && this.player) {
        this.player.play();
      } else if (this.player?.isPlaying()) {
        this.player.pause();
      }
    });
  }

  displayPlaylist() {
    const sidePlaylist = this.props.showVideoPlaylist === 'side';
    const {selectedVideoData} = this.state;

    if (
      (this.props.videoPlaylist && this.props.videoPlaylist.length === 0) ||
      !this.props.showVideoPlaylist
    ) {
      return false;
    }
    if (sidePlaylist) {
      const videoId = selectedVideoData ? selectedVideoData.id : this.props.metadata.video_id;
      const currentVidInd = this.props.videoPlaylist.findIndex(
        (el) => el.metadata.video_id === videoId
      );
      if (currentVidInd !== -1) {
        this.props.videoPlaylist.splice(currentVidInd, 1);
      }
    }
    return (
      <VideoPlaylist
        className={sidePlaylist ? 'sidePlaylist' : 'carousel'}
        streamItems={this.props.videoPlaylist}
        videoPlaylistStatus={this.props.videoPlaylistStatus}
        playSpecificVideo={this.playSpecificVideo}
        playlistId={this.playlistId}
        rightArrow={!sidePlaylist}
      />
    );
  }

  setRecommendedVideosMode(mode) {
    if (mode.display) {
      this.setState({showVideoCarousel: mode.display});
    }

    if (mode.viewedVideo) {
      const viewedVideos = this.state.viewedVideos;
      if (!this.state.viewedVideos.includes(mode.viewedVideo)) {
        this.setState({
          viewedVideos: viewedVideos.concat([mode.viewedVideo]),
        });
      }
    }

    if (mode.watchedVideo) {
      const watchedVideos = this.state.watchedVideos.slice();
      const index = watchedVideos.findIndex((video) => video.id === mode.watchedVideo.id);
      if (index === -1) {
        this.setState({watchedVideos: watchedVideos.concat([mode.watchedVideo])});
      } else {
        watchedVideos[index] = mode.watchedVideo;
        this.setState({watchedVideos});
      }
    }
  }

  onThumbClick() {
    const {thumbnailHref, isLiveVideoPlayable} = this.props;
    if (thumbnailHref) {
      global.location = thumbnailHref;
      return;
    }

    if (isLiveVideoPlayable === false) {
      return;
    }

    this.setState({thumbClicked: true});
    this.setRemainingRecommendedVideos(this.props.metadata.video_id);
  }

  onPlayCarouselVideo(videoData, event) {
    this.setRemainingRecommendedVideos(videoData.id);
    const contentPlacement = this.props.videoRecommendations.findIndex(
      (video) => video.video_id === videoData.id
    );
    this.setState(
      {
        thumbClicked: true,
        selectedVideoData: videoData,
        thumbnail: videoData.thumbnail,
        contentPlacement,
        progressType: event,
      },
      () => {
        this.setState({showVideoCarousel: false});
      }
    );
  }

  onPlayerStateChange = (videoState) => {
    const {handleContentBox} = this.props;
    if (handleContentBox) this.props.handleContentBox(videoState);
  };

  onMediaTimeChanged = (isDVRMode) => {
    this.props.onMediaTimeChanged(isDVRMode);
  };

  playSpecificVideo(selectedVideo, loadNewData) {
    this.props
      .playSpecificVideo(this.props.metadata.video_id, selectedVideo, this.playlistId, loadNewData)
      .then(() => {
        //videoPlaylist has metadata in array; videoRecommendations metadata in the videoMetadata prop
        const isPlaylist = this.props.videoPlaylist.length > 0;
        const thisList = isPlaylist ? this.props.videoPlaylist : this.props.videoRecommendations;
        const newVidInd = thisList.findIndex((el) =>
          isPlaylist ? el.metadata.video_id === selectedVideo : el.video_id === selectedVideo
        );
        const newVid = thisList[newVidInd];
        const title = isPlaylist
          ? newVid.metadata.title
          : this.props.videoMetadata[selectedVideo].title;
        const videoData = {
          id: selectedVideo,
          title,
          thumbnail: isPlaylist
            ? newVid.metadata.thumbnail_url
            : this.props.videoMetadata[selectedVideo].thumbnail_url,
        };
        this.setState({
          thumbClicked: true,
          selectedVideoData: videoData,
          thumbnail: videoData.thumbnail,
        });
        this._videoPlayer.onPlaySpecificVideo();
      });
  }

  playNextVideo(currentPlayer, playlistClicked) {
    this.props.playNextVideo(currentPlayer, playlistClicked, this.playlistId);
  }

  onVideoEnded() {
    this.props.onVideoEnded();
  }

  setVideoLastFrame(frame) {
    this.setState({videoLastFrame: frame});
  }

  trackPlaylistSummary() {
    const totalTimeSpent = this.state.watchedVideos.reduce((accumulator, video) => {
      return accumulator + video.duration;
    }, 0);

    this.props.trackEvent({
      author: this.props.author.id,
      title: this.props.title,
      total_videos_viewed: this.state.viewedVideos.length,
      total_videos_available: this.props.videoRecommendations.length,
      total_time_spent: totalTimeSpent,
      experiment_type: this.props.experimentType,
      tag_manager_event: 'video_playlist_summary',
    });
  }

  trackEventDetails({title, ui, league, isVideoLiveNow, isLoggedInUser, titleLinkUrl}, event) {
    event.preventDefault();
    this.props.trackEvent({
      // https://statmilk.atlassian.net/wiki/spaces/ENG/pages/2111766529/Pay+Per+View
      // https://statmilk.atlassian.net/wiki/spaces/ENG/pages/51085394/mParticle
      authStatus: isLoggedInUser,
      cta: 'Event Details',
      isLive: isVideoLiveNow,
      league: league?.name,
      screen: getScreenType(ui?.pageType, ui?.pageSubType) || 'PPV Event Details',
      tag_manager_event: 'event_details_selected',
      title,
    });
    window.location.href = titleLinkUrl;
  }

  handlePurchaseEvent() {
    const {
      isLoggedInUser,
      gizmoProduct,
      metadata,
      url,
      liveVideoMetadata,
      analytics,
      page,
    } = this.props;
    const videoMetadata = liveVideoMetadata[analytics.video_id];
    const ppvPurchaseLink = isLoggedInUser
      ? `/checkout?return=${BR.myEvents()}&liveEventId=${metadata.live_event_id}`
      : `/sign_in?return=${url}&screen=${page?.type || 'login'}`;

    this.props.trackEvent({
      // https://statmilk.atlassian.net/wiki/spaces/ENG/pages/2111766529/Pay+Per+View
      // https://statmilk.atlassian.net/wiki/spaces/ENG/pages/51085394/mParticle
      currencyCode: gizmoProduct.currency,
      skuID: videoMetadata.product?.sku,
      itemPrice: gizmoProduct.price,
      productTitle: videoMetadata.name,
      productType: videoMetadata.type?.toUpperCase(),
      league: videoMetadata.league?.name,
      screen: 'PPV Event Details',
      isLive: videoMetadata.state === videoStates.LIVE,
      tag_manager_event: 'ppv_purchase_initiated',
    });

    window.location.href = ppvPurchaseLink;
  }

  get pageSpecificAttributes() {
    const {page} = this.props;
    const attributes = {};

    if (this.isStubPage) {
      attributes.article_type = STUB;
    }

    if (page.type === ARTICLE) {
      attributes.placement_type = 'article_body';
    } else if (page.zone === 'video') {
      attributes.placement_type = 'article_L1';
    } else {
      attributes.placement_type = SECTION;
    }

    return attributes;
  }

  get thumbnail() {
    const {selectedVideoData} = this.state;
    return selectedVideoData ? selectedVideoData.thumbnail : this.props.thumbData.thumbnail;
  }

  get vmTitle() {
    const {selectedVideoData} = this.state;
    return selectedVideoData
      ? selectedVideoData.title
      : this.props.videoMetadata &&
          this.props.videoMetadata[this.props.metadata.video_id] &&
          this.props.videoMetadata[this.props.metadata.video_id].title;
  }

  getVideoPlayer({fromVideoCarousel = false}) {
    const {
      article,
      autoplay,
      badge,
      blackout,
      fetchPPVEntitlementById,
      index,
      isLiveVideo,
      media_id,
      metadata,
      oneTrustPreferences,
      setTveAuthContext,
      setTveProviderLogo,
      onVideoError,
      setAuthorizationResponse,
      trackEvent,
      type,
      ui,
      user,
      videoMetadata,
      videoRecommendations,
      page,
      banner,
    } = this.props;
    const {
      contentPlacement,
      isVideoInViewport,
      progressType,
      remainingRecommendedVideos,
      selectedVideoData,
      thumbClicked,
    } = this.state;

    // We get the videoID from the state if the carousel was displayed otherwise it is passed down from props
    let videoId = selectedVideoData ? selectedVideoData.id : metadata.video_id;
    if (!videoId && (metadata.id || metadata.analytics)) {
      videoId = metadata.id || metadata.analytics.video_id;
    }
    const hasRecommendations = videoRecommendations && videoRecommendations.length;
    const isTVE = type === videoTypes.TVE;
    const isPPV = type === videoTypes.PPV;

    // Once the article service can provide additional metadata for elements (KWB-269)
    // This should use `props.metadata` (Article service) instead of `props.videoMetadata` (Vid service)
    const playerMetadata = (videoMetadata && videoMetadata[videoId]) || metadata;
    const shouldAutoplay = () => {
      if (isTVE || isPPV) {
        return this.isStubPage;
      }
      return autoplay || thumbClicked;
    };
    const sponsoredAd = type === videoTypes.FREE && banner;
    let contentPlacementValue = null;
    if (this.state.showVideoCarousel) {
      contentPlacementValue = contentPlacement;
    } else if (!isNaN(index)) {
      contentPlacementValue = index + 1;
    }

    const isCreators = article.content_type === contentTypes.CREATORS;
    return process.env.TOP_PLAYER_ENABLED && (isLiveVideo || isCreators) ? (
      <Video
        autoplay={shouldAutoplay()}
        badge={badge}
        blackout={blackout}
        contentPlacement={contentPlacementValue}
        fetchPPVEntitlementById={fetchPPVEntitlementById}
        fromVideoCarousel={fromVideoCarousel}
        hasRecommendations={hasRecommendations}
        hlsMediaUrl={this.hlsMediaUrl}
        hookTitle={this.props.title}
        isLiveVideo={isLiveVideo}
        isStubPage={this.isStubPage}
        league={this.props.league}
        liveEventState={this.props.state}
        liveVideoMetadata={this.props.liveVideoMetadata}
        setAuthorizationResponse={setAuthorizationResponse}
        liveVideoOptions={{
          sponsoredAd,
          tveOptions: {
            isPPV,
            isTVE,
            isTVEFreePreview: this.props.free_preview,
            setTveAuthContext,
            setTveProviderLogo,
          },
        }}
        metadata={playerMetadata}
        mediaId={media_id}
        onBlackout={this.props.onBlackout}
        onError={onVideoError}
        oneTrustPreferences={oneTrustPreferences}
        onMediaTimeChanged={this.onMediaTimeChanged}
        onPlayerStateChange={this.onPlayerStateChange}
        page={page}
        pageSpecificAttributes={this.pageSpecificAttributes}
        posterImage={this.thumbnail}
        publishedDate={this.props.publishedDate}
        site={this.props.site}
        tags={this.props.tags}
        tagId={this.props.tagId}
        track={article}
        trackEvent={trackEvent}
        title={this.props.title}
        ui={ui}
        user={user}
        type={this.props.type}
        state={this.props.state}
        videoId={videoId}
        channel={this.props.tv_channel}
        saveUserEntitlements={this.props.saveUserEntitlements}
      />
    ) : (
      <VideoPlayer
        author={this.props.author}
        autoplay={autoplay || thumbClicked}
        addStyle={this.props.addStyle}
        badge={badge}
        contentType={article.content_type}
        experimentType={this.props.experimentType}
        handleContentBox={this.props.handleContentBox}
        isLiveVideo={isLiveVideo}
        isPlaylist={this.props.videoPlaylist && this.props.videoPlaylist.length > 0}
        isVideoInViewport={isVideoInViewport}
        isYoutube={this.props.isYoutube}
        hasRecommendations={hasRecommendations}
        hlsMediaUrl={this.hlsMediaUrl}
        liveVideoMetadata={this.props.liveVideoMetadata}
        loadScript={this.props.loadScript}
        metadata={playerMetadata}
        muted={this.props.muted}
        onHeaderClick={this.props.onHeaderClick}
        onVideoEnded={this.onVideoEnded}
        onVideoError={onVideoError}
        page={page}
        playlist={this.props.article.playlist_id}
        playNextVideo={this.playNextVideo}
        playSpecificVideo={this.playSpecificVideo}
        publishedDate={this.props.publishedDate}
        ref={(videoPlayer) => {
          this._videoPlayer = videoPlayer;
        }}
        setRecommendedVideosMode={this.setRecommendedVideosMode}
        site={this.props.site}
        tags={this.props.tags}
        thumbnail={this.thumbnail}
        title={this.props.title}
        track={article}
        trackEvent={trackEvent}
        trackID={this.props.article.id}
        ui={ui}
        resetSelectedVideo={this.resetSelectedVideo}
        updateVideoPlayerReference={this.updateVideoPlayerReference}
        updateVideoPlaybackList={this.props.updateVideoPlaybackList}
        user={user}
        videoRecommendations={this.props.videoRecommendations}
        remainingVideoRecommendations={remainingRecommendedVideos}
        setVideoLastFrame={this.setVideoLastFrame}
        progressType={progressType}
        contentPlacement={contentPlacementValue}
        trackPlaylistSummary={this.trackPlaylistSummary}
        videoMetadata={this.props.videoMetadata}
        showPlaylist={this.props.showVideoPlaylist}
        startSeconds={metadata?.start_seconds}
      />
    );
  }

  render() {
    const {
      article,
      isLiveVideo,
      badge,
      overlay,
      hasVideoPlayIcon = true,
      thumbnailClasses,
      videoRecommendations,
      isLoggedInUser,
      setTveAuthContext,
      setTveProviderLogo,
      ui,
      user,
      cloudinaryWidth,
    } = this.props;

    const {
      remainingRecommendedVideos,
      selectedVideoData,
      showVideoCarousel,
      videoLastFrame,
    } = this.state;
    const thumbnail = this.thumbnail;

    const isTVE = this.props.type === videoTypes.TVE;
    const isPPV = this.props.type === videoTypes.PPV;
    const hideTveEvent = isTVE && process.env.TVE_ENABLED !== 'true';
    const {context, authBlock} = ui && ui.tveAuthContext;
    const isTVEAuthenticated = context && context.authenticatedState === 'authenticated';
    const tveProviderLogo = ui.tveProviderLogo;
    const showTVE = process.env.TVE_ENABLED && isTVE;
    const showTVELogo = showTVE && process.env.ENABLE_MAX_TVE_MESSAGING === 'true';
    const authInitialized = authBlock && context && context.authenticatedState;
    const eventPurchased = false; // TODO: add regwall and strapi check for ownership
    const hideRecommendedVideoPlayer = this.props.page?.tags?.includes('No_Ads');
    /* TODO: update eventStartedOverlay to check for if video started already.
    temporarily use isLoggedInUser to mock "live event" */
    const eventStartedOverlay = this.isStubPage && isPPV && isLoggedInUser && eventPurchased;
    const classes = classnames('molecule nr-video-manager', {
      'is-live': isLiveVideo,
      'event-started': eventStartedOverlay,
      hide: hideRecommendedVideoPlayer,
      ppv: isPPV,
    });

    if (hideTveEvent) return null;

    if (this.isGeoBlocked) {
      return (
        <Photo
          altText="Sorry, this content is unavailable in your region."
          classNames="organism video-geoblocked"
          src={this.props.unavailableInRegionImageUrl}
        />
      );
    }

    const hasRecommendations = videoRecommendations && videoRecommendations.length;
    const userEntitlements = user.entitlements?.payload;
    const isPendingEntitlements = typeof userEntitlements === 'undefined';
    const isUserEntitled =
      user && userEntitlements && userEntitlements.length && userEntitlements[0].accessAllowed;
    const shouldShowPurchaseLink =
      !isPendingEntitlements &&
      this.isStubPage &&
      isPPV &&
      this.props.gizmoProduct &&
      !isUserEntitled;
    const ppvPurchaseLink = isLoggedInUser
      ? `/checkout?return=${BR.myEvents()}&liveEventId=${this.props.metadata.live_event_id}`
      : `/sign_in?return=${this.props.url}&screen=${this.props.page?.type || 'login'}`;

    const tveStubURL = isTVE && this.props.metadata?.share_url;
    const isValidTveStubTrack = tveStubURL && tveStubURL.includes('post');

    function onTveLoginClicked() {
      TveAuth.handleLogin({
        authBlock,
        setTveAuthContext,
        authorizeAndPlay: null,
        setTveProviderLogo,
        isValidTveStubTrack,
        tveStubURL,
      });
    }

    const videoPlayerThumbnail = (
      <div className="thumbnail-holder">
        <Thumbnail
          badge={badge}
          overlay={overlay}
          eventStartedOverlay={eventStartedOverlay}
          isVideo={true}
          onClick={this.onThumbClick}
          classes={thumbnailClasses}
          {...this.props.thumbData}
          thumbnail={thumbnail}
          cloudinaryWidth={cloudinaryWidth}
          title={this.props.title || this.props.name || this.props.thumbData?.title}
        >
          {!isPPV && hasVideoPlayIcon && !overlay ? <SVG type="icon" target="videoPlay" /> : null}
        </Thumbnail>
        {hasRecommendations ? <VideoIndicator /> : null}
        {shouldShowPurchaseLink ? (
          <>
            <a
              className="atom button purchase"
              href={ppvPurchaseLink}
              // https://reactjs.org/docs/handling-events.html#passing-arguments-to-event-handlers
              // eslint-disable-next-line react/jsx-no-bind
              onClick={(event) => {
                event.preventDefault();
                this.handlePurchaseEvent();
              }}
            >
              Purchase Event • ${this.props.gizmoProduct.price} {this.props.gizmoProduct.currency}
            </a>
            <p className="legal-notice">
              International charges may apply. Purchase provides access to the live stream and
              on-demand replay. Once the event is over, please allow 24 hours for the replay to be
              posted. The on-demand replay will be available for 72 hours. By purchasing you agree
              to Bleacher Report's <a href="/pages/terms">Terms of Use</a> and{' '}
              <a href={WarnerMedia.privacy()}>Privacy Policy</a>.
            </p>
          </>
        ) : (
          ''
        )}
        {isUserEntitled && (
          <span className="atom button purchase purchased">
            Event Purchased
            <img
              alt="check icon"
              className="check-mark"
              src={`${Static.images()}/atoms/svg/icons/check-mark.svg`}
            />
          </span>
        )}
        {showTVE && authInitialized && (
          <TveFooter
            isAuthenticated={isTVEAuthenticated}
            imageURL={tveProviderLogo}
            context={context}
            handleLogin={onTveLoginClicked}
          />
        )}
      </div>
    );

    const trackMetadata = this.props.metadata;
    const shouldShowLiveTrackCommentary = isPPV && !this.isHomePage;
    const shouldShowDetailsLink = !this.isHomePage && !this.isStubPage && isPPV;
    const isVideoLiveNow = badge?.text === videoStates.LIVE;
    const liveEventDateLabel = getFormattedLiveEventDate(trackMetadata, isVideoLiveNow);
    const {title, league} = this.props;

    function getTitleLinkUrl(article) {
      // Temporarily disable title links for creators type until standalone view is ready
      if (article.content_type !== contentTypes.CREATORS) {
        if ([contentTypes.HIGHLIGHT, contentTypes.VIDEO].includes(article.content_type)) {
          return article.permalink;
        }
        return article.externalUrl || article.url;
      }
      return '';
    }

    return (
      <this.props.tag
        className={classes}
        ref={(video) => {
          this._video = video;
        }}
      >
        <Commentary {...this.props.commentary} titleLinkUrl={getTitleLinkUrl(article)}>
          {liveEventDateLabel && shouldShowLiveTrackCommentary && <span>{liveEventDateLabel}</span>}
        </Commentary>
        {showTVELogo && <TveLogo />}
        {showVideoCarousel ? (
          <VideoCarousel
            playVideo={this.onPlayCarouselVideo}
            selectedVideo={selectedVideoData}
            ui={this.props.ui}
            videoMetadata={this.props.videoMetadata}
            videoRecommendations={remainingRecommendedVideos}
            videoLastFrame={videoLastFrame}
          />
        ) : this.shouldShowVideoPlayer ? (
          this.getVideoPlayer({fromVideoCarousel: true})
        ) : (
          videoPlayerThumbnail
        )}
        {shouldShowDetailsLink && (
          <a
            className="atom button details"
            href={this.props.article.url}
            // https://reactjs.org/docs/handling-events.html#passing-arguments-to-event-handlers
            // eslint-disable-next-line react/jsx-no-bind
            onClick={this.trackEventDetails.bind(this, {
              title,
              league,
              isVideoLiveNow,
              isLoggedInUser,
              titleLinkUrl: getTitleLinkUrl(article),
              ui,
            })}
          >
            Event Details
          </a>
        )}
        {this.props.showVideoPlaylist === 'side' && <h3 className="mobile">{this.vmTitle}</h3>}
        {this.displayPlaylist()}
        {this.props.showVideoPlaylist === 'side' && <h3 className="desktop">{this.vmTitle}</h3>}
      </this.props.tag>
    );
  }
}

function mapStateToProps(state) {
  const user = TopSelectors.user(state);
  const tags = Object.values(TopSelectors.tags(state) || {});
  const tagId = tags[0]?.tag_id;

  return {
    isLoggedInUser: isLoggedInUser(state),
    oneTrustPreferences: user.oneTrustPreferences,
    orbisSession: state.user.orbisSession,
    ui: state.ui,
    user: state.user,
    tagId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    trackEvent: (event) => dispatch(AnalyticsActions.trackEvent(event)),
    setTveAuthContext: (authContext) => dispatch(AppActions.setTveAuthContext(authContext)),
    setTveProviderLogo: (imageUrl) => {
      return dispatch(AppActions.setTveProviderLogo(imageUrl));
    },
    fetchPPVEntitlementById: (media_id) => dispatch(UserActions.fetchPPVEntitlementById(media_id)),
    saveUserEntitlements: (entitlements) =>
      dispatch(UserActions.saveUserEntitlements(entitlements)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withLiveVideo(VideoManager));
