import {PureComponent} from 'react';
import classnames from 'classnames';
import {buildQueryStringURL, getParams} from 'url-params-helper';

import IframeTracker from './iframeTracker';

class Iframe extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      src: false,
    };
    this.updateIframeSrc = this._updateIframeSrc.bind(this);
  }

  componentDidMount() {
    if (this.props.provider === 'nba.com') {
      // since the nba iframe isn't responsive and we have a partnership with them?
      // hardcoding numbers as a temp solution requested by product
      // rather than using this._iframe.offsetWidth as the width
      this.updateIframeSrc({
        width: 630, //this._iframe.offsetWidth,
        playerHeight: 355, //366 / 650 * this._iframe.offsetWidth
      });
    } else if (this.props.provider === 'mlb.com') {
      this.updateIframeSrc({
        width: this._iframe.offsetWidth,
        height: (366 / 650) * this._iframe.offsetWidth,
      });
    } else if (this.props.height) {
      this.updateIframeSrc({
        height: this.props.height || this.defaultProps.height,
      });
    }
  }

  _updateIframeSrc(newParams) {
    this.setState({
      src: buildQueryStringURL(
        {
          ...getParams(this.props.src),
          ...newParams,
        },
        this.props.src
      ),
    });
  }

  render() {
    const src = this.state.src || this.props.src;
    if (!src || typeof src !== 'string') {
      return false;
    }
    const isHttps = src.startsWith('https://');
    const content = isHttps ? (
      <IframeTracker src={src}>
        <iframe
          id={src}
          className="content"
          src={src}
          width="100%"
          height={this.props.height}
          frameBorder="0"
          allowFullScreen={true}
        />
      </IframeTracker>
    ) : (
      <a href={src} target="_blank" rel="noopener noreferrer">
        Link to Media
      </a>
    );

    // TODO request the api to pass provider without .
    const provider = this.props.provider ? this.props.provider.replace(/\./g, '') : false; // removes . from provider name
    const classes = classnames(this.props.className, {
      iframe: true,
      atom: true,
      [provider]: provider,
      error: !isHttps,
    });
    return (
      <this.props.tag
        className={classes}
        ref={(elem) => {
          this._iframe = elem;
        }}
      >
        {content}
      </this.props.tag>
    );
  }
}

Iframe.defaultProps = {
  getRef: () => {},
  height: '450px',
  src: '',
  tag: 'div',
};

export default Iframe;
