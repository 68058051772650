import {Static} from '../../endpoints';

const TveLogo = () => {
  const maxIcon = `${Static.images()}/atoms/svg/icons/max-icon.svg`;

  return (
    <div className="atom tve-logo">
      <span className="logo">
        Bleacher Report on&nbsp;
        <img alt="max-icon" className="maxIcon" src={maxIcon} />
      </span>
    </div>
  );
};

export default TveLogo;
