import React from 'react';
import logger from '../../../logger';
import * as tagManagerEvents from './analytics/tagManagerEvents';
import * as VideoHelpers from '../../helpers/videoHelpers';
import Badge from '../../atoms/badge';
import Banner from '../../atoms/banner';
import {getScreenType} from '../../../constants/mParticle';

const BADGE_CONTAINER_ID = 'badge-container';
const BANNER_CONTAINER_ID = 'banner-container';
const PAUSED = 'paused';
const PLAYING = 'playing';
const STOPPED = 'stopped';
const AdTimeoutCheckInterval = 1000;

const noop = () => {};

export function adEvents({trackEvent, sharedMediaAttributes, completionInterval, dispatch}) {
  return {
    adLoaded: () => {
      dispatch({
        payload: {
          adLoaded: true,
        },
      });
    },
    adStarted: () => {
      trackEvent({
        ...sharedMediaAttributes(),
        ad_type: 'pre_roll',
        tag_manager_event: tagManagerEvents.AD_PLAY,
      });
      logger.log('video ad started');
    },
    adFinished: () => {
      trackEvent({
        ...sharedMediaAttributes(),
        ad_type: 'pre_roll',
        completion_interval: completionInterval(),
        content_duration: 0,
        tag_manager_event: tagManagerEvents.AD_PLAY_SUMMARY,
      });
      logger.log('video ad finished');
      dispatch({
        payload: {
          adFinished: true,
        },
      });
    },
    adError: () => {
      logger.log('video ad error');
    },
  };
}

export function getInternalMediaPlaybackEvents({
  buildConfig,
  dispatch,
  trackEvent,
  sharedMediaAttributes,
  player,
  isTVE,
  props,
  showFullScreenTVEInfo,
  sponsoredAd,
  state,
}) {
  return {
    mediaLoaded: () => {
      logger.log('video media loaded; setup ads');
      setTimeout(() => {
        // This timeout check is a workaround for a missing TOP player feature
        // The TOP api doesn't provide us with a way to check if the media currently
        // playing is an ad until it's already loaded
        dispatch({
          payload: {
            AdTimeoutCheckTriggered: true,
          },
        });
      }, AdTimeoutCheckInterval);
    },
    muteChanged: ({muted}) => {
      dispatch({
        payload: {
          isMuted: muted,
        },
      });
      trackEvent({
        ...sharedMediaAttributes(),
        player_time: player.current.mediaTime,
        tag_manager_event: muted ? tagManagerEvents.CONTENT_MUTE : tagManagerEvents.CONTENT_UNMUTE,
      });
      logger.log('video mute changed', muted);
    },
    viewModeChanged: (event) => {
      if (event.currentValue === 'fullscreen') {
        const isPortraitOrientation =
          global.matchMedia && global.matchMedia('(orientation: portrait)').matches;
        dispatch({
          payload: {
            isFullScreen: true,
          },
        });
        if (isTVE) {
          showFullScreenTVEInfo();
        }
        if (state.isPlaying && sponsoredAd) {
          VideoHelpers.showFullScreenContent(
            <Banner {...sponsoredAd} isFullScreen={true} />,
            BANNER_CONTAINER_ID
          );
        }
        trackEvent({
          ...sharedMediaAttributes(),
          player_time: player.current.mediaTime,
          tag_manager_event: tagManagerEvents.CONTENT_FULLSCREEN,
          orientation: isPortraitOrientation ? 'portrait' : 'landscape',
        });
      } else {
        dispatch({
          payload: {
            isFullScreen: false,
          },
        });
        VideoHelpers.removeAllFullScreenElements();
      }

      const viewModeConfig = buildConfig({
        playerProps: {
          ...props,
          viewMode: event.currentValue,
        },
      });
      player.current.updateConfig(viewModeConfig);
      logger.log('video view state changed event:', event);
    },
  };
}

export function mediaPlaybackEvents({
  buildConfig,
  dispatch,
  freeView,
  isValidTveStubTrack,
  player,
  props,
  sharedMediaAttributes,
  showFullScreenTVEInfo,
  state,
  trackContentPlaySummary,
  trackEvent,
  tveStubURL,
  uiConfigurations,
  useFreePreview,
}) {
  const {
    badge,
    isStubPage,
    liveVideoOptions,
    onContentEnded = noop,
    onError = noop,
    onMediaTimeChanged = noop,
    onPause = noop,
    onPlayerStateChange = noop,
    onResume = noop,
    onStart = noop,
    onStop = noop,
  } = props;

  const isTVE =
    liveVideoOptions && liveVideoOptions.tveOptions && liveVideoOptions.tveOptions.isTVE;
  const sponsoredAd = (liveVideoOptions && liveVideoOptions.sponsoredAd) || null;
  const internalMediaPlaybackEvents = getInternalMediaPlaybackEvents({
    buildConfig,
    dispatch,
    isTVE,
    player,
    props,
    sharedMediaAttributes,
    showFullScreenTVEInfo,
    trackEvent,
    sponsoredAd,
    state,
  });
  return {
    ...internalMediaPlaybackEvents,
    contentEnded: () => {
      onContentEnded();
      logger.log('video content has ended');

      if (!player || !player.current) return;

      trackContentPlaySummary();

      dispatch({
        payload: {
          isPlaying: false,
        },
      });

      if (useFreePreview && freeView.current) {
        freeView.current.endSession();
      }
    },
    contentError: (err) => {
      !state.isFreePreviewExpired && onError(err);
      trackEvent({
        ...sharedMediaAttributes(),
        error_time: player ? player.current.mediaTime : Date.now(),
        tag_manager_event: tagManagerEvents.CONTENT_PLAY_ERROR,
      });
      logger.error('video content error', err);
    },
    contentStateChanged: (state) => {
      if (
        isTVE &&
        state.previousState === 'waiting' &&
        !isValidTveStubTrack &&
        !isStubPage &&
        tveStubURL
      ) {
        window.location.href = tveStubURL.replace(/""/, '');
      }
    },
    mediaError: (err) => {
      if (useFreePreview && freeView.current) {
        freeView.current.endSession();
      }
      !state.isFreePreviewExpired && onError(err);
    },
    mediaFinished: () => {
      if (useFreePreview && freeView.current) {
        freeView.current.endSession();
      }
    },

    mediaPaused: () => {
      const pauseConfig = buildConfig({
        playerProps: props,
        ui: uiConfigurations.paused,
      });
      player.current.updateConfig(pauseConfig);

      VideoHelpers.showFullScreenContent(<Badge {...badge} />, BADGE_CONTAINER_ID);
      if (sponsoredAd) {
        VideoHelpers.removeFullScreenElement(BANNER_CONTAINER_ID);
      }

      onPause();
      onPlayerStateChange(PAUSED);

      trackEvent({
        ...sharedMediaAttributes(),
        player_time: player.current.mediaTime,
        tag_manager_event: tagManagerEvents.CONTENT_PAUSE,
      });

      dispatch({
        payload: {
          isPaused: true,
          isPlaying: false,
        },
      });

      logger.log('video is paused');
    },
    mediaResumed: () => {
      const resumeConfig = buildConfig({
        playerProps: props,
        ui: uiConfigurations.full,
      });
      player.current.updateConfig(resumeConfig);
      VideoHelpers.removeFullScreenElement(BADGE_CONTAINER_ID);
      trackEvent({
        tag_manager_event: 'content_selected',
        mediaID: props.mediaId,
        urlHash: props.ui?.url,
        screen: getScreenType(props.ui?.pageType, props.ui?.pageSubType),
        contentType: 'video',
        springType: props.hasRecommendations ? 'video_subCarousel' : 'stream',
        title: props.track?.metadata?.title,
      });
      onResume();
      onPlayerStateChange(PLAYING);
      dispatch({
        payload: {
          isPaused: false,
          isPlaying: true,
        },
      });
      if (sponsoredAd) {
        VideoHelpers.showFullScreenContent(
          <Banner {...sponsoredAd} isFullScreen={state.isFullScreen} />,
          BANNER_CONTAINER_ID
        );
      }
      logger.log('video resumed playing');
    },
    mediaStarted: () => {
      onStart();
      onPlayerStateChange(PLAYING);
      trackEvent({
        ...sharedMediaAttributes(),
        player_time: player.current.mediaTime,
        tag_manager_event: tagManagerEvents.CONTENT_PLAY,
      });
      trackEvent({
        tag_manager_event: 'content_selected',
        mediaID: props.mediaId,
        urlHash: props.ui?.url,
        screen: getScreenType(props.ui?.pageType, props.ui?.pageSubType),
        contentType: 'video',
        springType: props.hasRecommendations ? 'video_subCarousel' : 'stream',
        title: props.track?.metadata?.title,
      });
      dispatch({
        payload: {
          isPlaying: true,
        },
      });
      const playConfig = buildConfig({
        playerProps: props,
        ui: uiConfigurations.full,
      });
      player.current.updateConfig(playConfig);
      logger.log('video is playing', state);
    },
    mediaStopped: () => {
      onStop();
      onPlayerStateChange(STOPPED);
      logger.log('video is stopped');

      if (!player) return;

      if (useFreePreview && freeView.current) {
        freeView.current.endSession();
      }

      dispatch({
        payload: {
          isPlaying: false,
        },
      });
    },

    mediaTimeChanged: (result) => {
      const currentTime = player.current.mediaTime;
      const range = player.current.mediaSeekableRange;
      const isDVRMode = !(currentTime > range?.end - 10);

      onMediaTimeChanged(isDVRMode);
      if (isDVRMode !== state.isDVRMode) {
        dispatch({
          payload: {
            isDVRMode,
          },
        });
      }

      // Use players time progression events to
      // increment the time spent to freeview every
      // second of playback.

      // Round the time down to the closest second
      if (useFreePreview && freeView.current) {
        const roundedMediaTime = Math.floor(result.time);

        // if the new time is different than the current time
        // rounded to a second, increment the time spent.
        if (roundedMediaTime !== state.currentMediaTime) {
          dispatch({
            payload: {
              currentMediaTime: roundedMediaTime,
            },
          });
          freeView.current.incrementTimeSpent();
        }
      }
    },
    playerError: (err) => {
      if (useFreePreview && freeView.current) {
        freeView.current.endSession();
      }
      !state.isFreePreviewExpired && onError(err);
      trackEvent({
        ...sharedMediaAttributes(),
        error_time: player ? player.current.mediaTime : Date.now(),
        tag_manager_event: tagManagerEvents.CONTENT_PLAY_ERROR,
      });
      logger.error('video player errored', err);
    },
  };
}

export function freePreviewEvents({dispatch, player, onPreviewExpired}) {
  return {
    sessionStarted: (result) => {
      dispatch({
        payload: {
          freePreviewCountdown: result.timeRemaining,
          freeViewToken: result.jwt,
        },
      });
    },
    sessionProgress: (result) => {
      dispatch({
        payload: {
          freePreviewCountdown: result.timeRemaining,
          freeViewToken: result.jwt,
        },
      });
    },
    sessionExpired: () => {
      dispatch({
        payload: {
          freePreviewCountdown: 0,
          isFreePreviewExpired: true,
          currentMediaTime: -1,
        },
      });
      onPreviewExpired();
      player.current.stop();
    },
    sessionEnded: () => {
      dispatch({
        payload: {
          currentMediaTime: -1,
        },
      });
    },
  };
}

export function getAllVideoEvents(config) {
  return {
    ...mediaPlaybackEvents(config),
    ...adEvents(config),
  };
}
