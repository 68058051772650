import React from 'react';
import classnames from 'classnames';
import {SVG} from '@br/br-components';
import {connect} from 'react-redux';

import * as AnalyticsActions from '../../../actions/analyticsActions';
import {getScreenType} from '../../../constants/mParticle';
import Photo from '../../atoms/photo';

class VideoPlaylist extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      position: 0,
      rightArrow: this.props.rightArrow === false ? this.props.rightArrow : true,
      leftArrow: false,
      currentPlayingIndex: null,
    };

    this.increment = 96;
    this.thumbWidth = 24;
    this.gutters = 2;
    // number of thumbnails * %width of each thumbnail - 100% (because something must always be in view)
    this.videoPlaylistWidth = this.props.streamItems.length * this.thumbWidth - 100;

    this.shiftLeft = this.shiftLeft.bind(this);
    this.shiftRight = this.shiftRight.bind(this);
    this.shiftPlaylist = this.shiftPlaylist.bind(this);
    this.buildPlaylist = this.buildPlaylist.bind(this);
  }

  componentDidUpdate(_prevProps, prevState) {
    // TODO: remove typeof and check for undefined
    if (
      this.currentPlayingIndex !== null &&
      typeof this.currentPlayingIndex !== 'undefined' &&
      prevState.currentPlayingIndex !== this.currentPlayingIndex
    ) {
      const newPosition = this.currentPlayingIndex * this.thumbWidth;
      this.shiftPlaylist(-newPosition);
    }
  }

  buildPlaylist() {
    const isSidePlaylist = this.props.className === 'sidePlaylist';
    const handleClick = (selectedVideo, item) => {
      const screenType = getScreenType(this.props.ui?.pageType, this.props.ui?.pageSubType);
      this.props.trackEvent({
        tag_manager_event: 'content_selected',
        urlHash: item.content.metadata.thumbnail_url,
        screen: screenType,
        contentType: 'video',
        springType:
          screenType === 'Video Destination - Home'
            ? 'video_featuredCarousel'
            : 'video_subCarousel',
        title: item.content.metadata.title,
      });
      this.props.playSpecificVideo(selectedVideo);
    };
    return this.props.streamItems.map((item, index) => {
      let currentlyPlaying;

      if (
        this.props.videoPlaylistStatus &&
        this.props.videoPlaylistStatus[this.props.playlistId] === item.content.metadata.video_id
      ) {
        currentlyPlaying = true;
        this.currentPlayingIndex = index;
      }
      return (
        <li key={`video-${this.props.playlistId}-${item.content.metadata.video_id}`}>
          <Photo
            title={item.content.metadata.title}
            src={item.content.metadata.thumbnail_url}
            id={item.content.metadata.video_id}
            handleImageClick={(selectedVideo) => handleClick(selectedVideo, item)}
            selected={currentlyPlaying}
            // .imgWrapper in videoPlaylist.scss gives max width of 120px
            size={isSidePlaylist ? {w: 120, h: 80} : {w: 288, h: 192}}
            duration={item.content.metadata.duration}
            showDuration={isSidePlaylist}
          />
        </li>
      );
    });
  }

  shiftLeft() {
    // if we're at the start of the carousel
    if (this.state.position < 0) {
      const newPosition = this.state.position + this.increment;
      this.shiftPlaylist(newPosition);
    }
  }

  shiftRight() {
    // if we're already at the end
    if (this.state.position > -this.videoPlaylistWidth) {
      const newPosition = this.state.position - this.increment;
      this.shiftPlaylist(newPosition);
    }
  }

  shiftPlaylist(position) {
    let newPosition = position;
    // adjusts position so it never goes past the last thumbnail
    if (position < -this.videoPlaylistWidth) {
      newPosition = -this.videoPlaylistWidth + this.gutters;
      // adjusts position to never go past first thumbnail
    } else if (position > 0) {
      newPosition = 0;
    }

    this.setState({
      currentPlayingIndex: this.currentPlayingIndex,
      position: newPosition,
      leftArrow: newPosition !== 0,
      rightArrow: newPosition !== -this.videoPlaylistWidth + this.gutters,
    });
  }

  render() {
    const classes = classnames(this.props.className, {
      videoPlaylist: true,
      molecule: true,
    });
    return (
      <div className={classes}>
        {this.state.leftArrow ? (
          <SVG type="icon" target="leftArrow" onClick={this.shiftLeft} />
        ) : (
          false
        )}
        <ol style={{left: `${this.state.position}%`}}>{this.buildPlaylist()}</ol>
        {this.state.rightArrow ? (
          <SVG type="icon" target="rightArrow" onClick={this.shiftRight} />
        ) : (
          false
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ui: state.ui,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEvent: (event) => dispatch(AnalyticsActions.trackEvent(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlaylist);
