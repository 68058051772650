import React, {PureComponent} from 'react';
import {SVG} from '@br/br-components';
import PropTypes from 'prop-types';

import Thumbnail from './thumbnail';

export default class VideoCarousel extends PureComponent {
  static propTypes = {
    playVideo: PropTypes.func,
    selectedVideo: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      thumbnail: PropTypes.string,
    }),
    ui: PropTypes.shape({
      assetURL: PropTypes.string,
      isMobileDevice: PropTypes.bool,
    }),
    videoMetadata: PropTypes.objectOf(
      PropTypes.shape({
        thumbnail_url: PropTypes.string,
        title: PropTypes.string,
      })
    ),
    videoRecommendations: PropTypes.arrayOf(
      PropTypes.shape({
        video_id: PropTypes.number,
      })
    ),
  };

  constructor(props) {
    super(props);
    this.shiftRight = this.shiftRight.bind(this);
    this.shiftLeft = this.shiftLeft.bind(this);
    this.onReplay = this.onReplay.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onClickThumbnail = this.onClickThumbnail.bind(this);
    this.increment = 93;
    this.thumbWidth = 32;
    this.gutters = 2;
    // number of videos * %width of each thumbnail - 100%
    this.videoPlaylistWidth = this.props.videoRecommendations.length * this.thumbWidth - 100;
    this.state = {
      displayRightArrow: this.videoPlaylistWidth > 0,
      displayLeftArrow: null,
      remainingTime: null, // time remaining in seconds for the next video to be played.
      countdownTimer: 1, // A timer that counts down till the next video plays automatically
      isThumbClicked: false,
      displayCarousel: true,
      position: 0,
    };
  }

  componentDidMount() {
    this.playNextVideo();
  }

  componentWillUnmount() {
    if (this.state.countdownTimer) {
      clearInterval(this.state.countdownTimer);
    }
  }

  playNextVideo() {
    const {videoRecommendations, videoMetadata} = this.props;
    const video = videoRecommendations[0];
    const metadata = videoMetadata[video.video_id];
    const thumbData = {
      thumbnail: video.video_thumbnail || metadata.thumbnail_url,
    };
    const title = metadata.title;
    const videoData = {id: video.video_id, title, thumbnail: thumbData.thumbnail};
    let remainingTime = 5;
    const countdownTimer = setInterval(() => {
      this.setState({countdownTimer, remainingTime: remainingTime--}, () => {
        if (this.state.isThumbClicked) {
          clearInterval(this.state.countdownTimer);
          this.setState({countdownTimer: 0});
        }
        if (this.state.remainingTime === 0) {
          const event = {progress_type: `auto_advance`};
          this.setState({countdownTimer: 0});
          clearInterval(this.state.countdownTimer);
          this.props.playVideo(videoData, event);
        }
      });
    }, 1000);
  }

  onClose() {
    this.setState({displayCarousel: false});
  }

  onReplay() {
    const {selectedVideo} = this.props;
    clearInterval(this.state.countdownTimer);
    this.setState({countdownTimer: 0});
    this.props.playVideo(selectedVideo);
  }

  onClickThumbnail(videoData) {
    const event = {progress_type: 'manual'};
    this.setState({isThumbClicked: true});
    this.props.playVideo(videoData, event);
  }

  shiftLeft() {
    // if we're at the start of the carousel
    if (this.state.position < 0) {
      const newPosition = this.state.position + this.increment;
      this.shiftPlaylist(newPosition);
    }
    clearInterval(this.state.countdownTimer);
    this.setState({countdownTimer: 0});
  }

  shiftRight() {
    // if we're already at the end
    if (this.state.position > -this.videoPlaylistWidth) {
      const newPosition = this.state.position - this.increment;
      this.shiftPlaylist(newPosition);
    }
    clearInterval(this.state.countdownTimer);
    this.setState({countdownTimer: 0});
  }

  shiftPlaylist(position) {
    let newPosition = position;

    // adjusts position so it never goes past the last thumbnail
    if (position < -this.videoPlaylistWidth) {
      newPosition = -this.videoPlaylistWidth + this.gutters;
      // adjusts position to never go past first thumbnail
    } else if (position > 0) {
      newPosition = 0;
    }

    this.setState({
      position: newPosition,
      displayLeftArrow: newPosition !== 0,
      displayRightArrow: newPosition !== -this.videoPlaylistWidth + this.gutters,
    });
  }

  render() {
    const {
      videoLastFrame,
      ui: {assetURL = '', isMobileDevice},
      selectedVideo: {title = ''},
      videoRecommendations,
    } = this.props;

    const remainingTimeText = this.state.remainingTime
      ? `Up next in ${this.state.remainingTime}...`
      : '';

    const displayMessage = this.state.countdownTimer ? remainingTimeText : 'Related Videos';

    return this.state.displayCarousel ? (
      <div className="molecule videoCarousel">
        <img alt="main thumbnail" src={videoLastFrame} className="mainThumbnail" />
        <div className="carouselHeader">
          <span className=" text articleTitle">{title}</span>
          <div>
            <img
              className="replay"
              alt="replay"
              onClick={this.onReplay}
              src={`${assetURL}/img/video-player/replay.svg`}
            />
            {isMobileDevice && <SVG type="icon" target="close" onClick={this.onClose} />}
          </div>
        </div>
        <span className=" text videoHeader"> {displayMessage}</span>
        <ol className="recommendedVideos" style={{left: `${this.state.position}%`}}>
          {videoRecommendations.map((video, index) => {
            const thumbData = {
              thumbnail: this.props.videoMetadata[video.video_id].thumbnail_url,
            };
            const title = this.props.videoMetadata[video.video_id].title;
            const videoData = {id: video.video_id, title, thumbnail: thumbData.thumbnail};
            const readyToPlay = index === 0;

            return (
              <li className="video" key={index}>
                <Thumbnail
                  key={index}
                  readyToPlay={readyToPlay}
                  // onClickThumbnail method uses videoData
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => {
                    this.onClickThumbnail(videoData);
                  }}
                  {...thumbData}
                >
                  {readyToPlay && <SVG type="icon" target="videoPlay" />}
                </Thumbnail>
                <p className=" text videoTitle">{title}</p>
              </li>
            );
          })}
        </ol>
        {this.state.displayLeftArrow && (
          // parameter is passed to onClickArrow method
          // eslint-disable-next-line react/jsx-no-bind
          <SVG type="icon" target="leftArrow" onClick={() => this.shiftLeft()} />
        )}
        {this.state.displayRightArrow && (
          // parameter is passed to onClickArrow method
          // eslint-disable-next-line react/jsx-no-bind
          <SVG type="icon" target="rightArrow" onClick={() => this.shiftRight()} />
        )}
      </div>
    ) : null;
  }
}

VideoCarousel.defaultProps = {
  videoRecommendations: [],
};
